import axios from "axios";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);

var history_mixin = {
    VueCookies,
    methods: {
        pushHistory: function(product_id){
            var history_list = [];
            console.log(this.$cookies.get('history_list'));
            if(this.$cookies.get('history_list') != null){
                var temp = this.$cookies.get('history_list');
                history_list = JSON.parse(temp);
            }

            if(!history_list.includes(product_id)) {
                if (history_list.length >= 20) {
                    history_list.shift();
                }
                history_list.push(product_id);
            }

            this.$cookies.set('history_list', JSON.stringify(history_list), '1y');
        },
        removeHistoryByProductID: function(product_id){
            var history_list = [];
            if(this.$cookies.get('history_list') != null){
                var temp = this.$cookies.get('history_list');
                history_list = JSON.parse(temp);

                history_list.splice(history_list.indexOf(product_id), 1);

                this.$cookies.set('history_list', JSON.stringify(history_list), '1y');
            }
        },
        getHistory: function(){
            return this.$cookies.get('history_list');
        }
    }
};

export default history_mixin;