import axios from "axios";
import store from "../store/main_store";
import VueInternationalization from 'vue-i18n';
import trans from '../../js/vue-i18n-locales.generated';
import {ToastPlugin} from 'bootstrap-vue';
import lineClamp from "vue-line-clamp";
import jquery from "jquery";
import debounce from "lodash/debounce";


Vue.use(VueInternationalization);
Vue.use(lineClamp);
Vue.use(ToastPlugin);

const i18n = new VueInternationalization({
    locale: use_locale,
    messages: trans
});

var cart_mixin = {
    store,
    methods: {
        //Get Cart and Cart Content
        getCart: function () {
            var component = this;
            var route = get_cart_route;
            axios.post(route, {
                csrf_token: this.csrf_field
            }).then(function (response) {
                console.log(response);
                component.$store.commit('changeCart', {cart: response.data});
            }).catch(function (error) {
                console.log(error);
                component.error();
            });
        },
        //Add Product To Cart
        addToCart: function (e, product_id, quantity, related_discount_items, maintenance_fee = null) {
            if (quantity < 1) {
                return this.error();
            }

            var component = this;
            var route = add_cart_route;
            axios.post(route, {
                item: {
                    product_id: product_id,
                    quantity: quantity,
                    related_discount_items: related_discount_items,
                    maintenance_fee: maintenance_fee != null ? maintenance_fee : 0,
                    express_checkout: false
                },
                csrf_token: this.csrf_field
            }).then(function (response) {
                component.$store.commit('changeCart', {cart: response.data});
                component.cartUpdated();
                if (response.data['login_page'])
                    window.location.href = response.data['login_page'];
            }).catch(function (error) {
                console.log(error);
                component.error();
            });
        },
        addToCartExpress: function (e, product_id, quantity, related_discount_items, maintenance_fee = null) {
            if (quantity < 1) {
                return this.error();
            }

            var component = this;
            var route = add_cart_route;
            axios.post(route, {
                item: {
                    product_id: product_id,
                    quantity: quantity,
                    related_discount_items: related_discount_items,
                    maintenance_fee: maintenance_fee != null ? maintenance_fee : 0,
                    express_checkout: true
                },
                csrf_token: this.csrf_field
            }).then(function (response) {
                component.$store.commit('changeCart', {cart: response.data});
                component.cartUpdated();
                //console.log(response.data['express_checkout'], response.data)
                if (response.data['express_checkout'])
                    window.location.href = response.data['express_checkout'];
            }).catch(function (error) {
                console.log(error);
                component.error();
            });
        },
        changeToCartItem: debounce(function (payload) {
            var component = this;
            var route = change_cart_item_route;
            var basic_payload = {
                csrf_token: this.csrf_field
            };
            jquery.extend(basic_payload, payload);
            axios.post(route, basic_payload).then(function (response) {
                console.log(response);
                component.$store.commit('changeCart', {cart: response.data});
                component.getAvailableDeliveryMethod();
                component.cartUpdated();
            }).catch(function (error) {
                console.log(error);
                component.error();
            });
        }, 200),
        removeCartItem: function (cart_item_id) {
            var component = this;
            var route = remove_cart_item_route;
            axios.post(route, {
                cart_item_id: cart_item_id,
                csrf_token: this.csrf_field
            }).then(function (response) {
                console.log(response);
                component.$store.commit('changeCart', {cart: response.data});
                component.cartUpdated();
            }).catch(function (error) {
                console.log(error);
                component.error();
            });
        },
        moveCartItemToFavItem: function (item) {
            var component = this;
            var route = move_from_cart_to_fav_route;
            axios.post(route, {
                item: item,
                csrf_token: this.csrf_field
            }).then(function (response) {
                console.log(response);
                component.$store.commit('changeCart', {cart: response.data});
                component.cartUpdated();
                component.getFav();
            }).catch(function (error) {
                console.log(error);
                component.error();
            });
        },
        //Favlist additional function...don't know where to place
        getFav: function () {
            var component = this;
            var route = get_fav_route;
            axios.post(route, {
                csrf_token: this.csrf_field
            }).then(function (response) {
                console.log(response);
                component.$store.commit('changeFav', {fav: response.data});
            }).catch(function (error) {
                console.log(error);
                component.error();
            });
        },
        addToFav: function (e, product_id, quantity, related_discount_items) {
            if (quantity < 1) {
                return this.error();
            }

            var component = this;
            var route = add_fav_route;
            axios.post(route, {
                item: {
                    product_id: product_id,
                    quantity: quantity,
                    related_discount_items: related_discount_items
                },
                csrf_token: this.csrf_field
            }).then(function (response) {
                console.log(response);
                component.$store.commit('changeFav', {fav: response.data});
                component.favUpdated();
            }).catch(function (error) {
                console.log(error);
                component.error();
            });
        },
        changeToFavItem: debounce(function (payload) {
            var component = this;
            var route = change_fav_item_route;
            var basic_payload = {
                csrf_token: this.csrf_field
            };
            jquery.extend(basic_payload, payload);
            axios.post(route, basic_payload).then(function (response) {
                console.log(response);
                component.$store.commit('changeFav', {fav: response.data});
                component.favUpdated();
            }).catch(function (error) {
                console.log(error);
                component.error();
            });
        }, 200),
        removeFavItem: function (fav_item_id) {
            var component = this;
            var route = remove_fav_item_route;
            axios.post(route, {
                favlist_item_id: fav_item_id,
                csrf_token: this.csrf_field
            }).then(function (response) {
                console.log(response);
                component.$store.commit('changeFav', {fav: response.data});
                component.favUpdated();
            }).catch(function (error) {
                console.log(error);
                component.error();
            });
        },
        getAvailableDeliveryMethod() {
            var component = this;
            var route = this.$parent.$children[1].$root.$el.getAttribute('get_available_delivery_methods_route');
            var country = this.$parent.$children[1].picked_shipment_country
            this.$parent.$children[1].available_delivery_method = [];
            if (this.$parent.$children[1].picked_delivery_method != null || this.$parent.$children[1].picked_delivery_method != "null") {
                this.$parent.$children[1].picked_delivery_method = null;
                this.deliveryInfo(this.$t('frontend.update_cart'));
            }
            axios.post(route, {
                country: country,
                csrf_token: this.csrf_field
            }).then(function (response) {
                console.log(response);
                component.$parent.$children[1].available_delivery_method = response.data
            }).catch(function (error) {
                console.log(error);
                component.error();
            });

        },
        moveFavItemToCartItem: function (item) {
            var component = this;
            var route = move_from_fav_to_cart_route;
            axios.post(route, {
                item: item,
                csrf_token: this.csrf_field
            }).then(function (response) {
                console.log(response);
                component.$store.commit('changeFav', {fav: response.data});
                component.cartUpdated();
                component.getCart();
            }).catch(function (error) {
                console.log(error);
                component.error();
            });
        },
        cartUpdated: function () {
            this.$bvToast.toast(this.$t('frontend.cart_updated'), {
                title: this.$t('frontend.cart'),
                solid: true,
                variant: 'info',
                autoHideDelay: 2000,
            });
        },
        dhlError: function (message) {
            this.$bvToast.toast(message, {
                title: this.$t('frontend.cart_dhl_error'),
                solid: true,
                variant: 'danger',
                autoHideDelay: 2000,
            });
        },
        deliveryInfo: function (message) {
            this.$bvToast.toast(message, {
                title: this.$t('frontend.cart'),
                solid: true,
                variant: 'info',
                autoHideDelay: 2000,
            });
        },
        alertCheckoutError: function (message) {
            this.$bvToast.toast(message, {
                title: this.$t('frontend.network_error'),
                solid: true,
                variant: 'danger',
                autoHideDelay: 2000,
            });
        },
        favUpdated: function () {
            this.$bvToast.toast(this.$t('frontend.fav_updated'), {
                title: this.$t('frontend.favourite_list'),
                solid: true,
                variant: 'info',
                autoHideDelay: 2000,
            });
        },
        couponUsed: function () {
            this.$bvToast.toast(this.$t('frontend.apply_success'), {
                title: this.$t('frontend.apply_success'),
                solid: true,
                variant: 'info',
                autoHideDelay: 2000,
            });
        },
        couponError: function () {
            this.$bvToast.toast(this.apply_message, {
                title: this.apply_message,
                solid: true,
                variant: 'danger',
                autoHideDelay: 2000,
            });
        },
        error: function () {
            this.$bvToast.toast(this.$t('frontend.please_retry_later'), {
                title: this.$t('frontend.network_error'),
                solid: true,
                variant: 'danger',
                autoHideDelay: 2000,
            });
        }
    }
};

export default cart_mixin;