import 'es6-promise/auto';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        member: null,
        is_login: false,
        cart: [],
        cart_total_weight: 0,
        cart_batch_weight: [],
        cart_filled_form: [],
        earn_point: 0,
        next_level_info: {},
        redeemable_point: [],
        fav: [],
        search_criteria: {
            search_input_product_name: null,
            search_input_product_type: 265,//'all',
            search_input_product_seo_url: null,
            search_input_product_genre: 'all',
            search_input_product_version: 'all',
            search_input_product_gamelang: 'all',
            search_input_product_label: 'all',
            search_input_product_number_player_online: 'all',
            search_input_product_number_player_offline: 'all',
            search_input_additional_rules: 'null',
            search_input_sorting_criteria: 3, //sort by create date
            search_input_product_create_date_from: '',
            search_input_product_create_date_to: '',
            search_input_product_price: '',
            page: 1,
        }
    },
    getters: {
        isLoggedIn: function(state){
            return state.is_login;
        },
        getUser: function(state) {
            return state.member;
        },
        getCart: function(state){
            return state.cart;
        },
        getCartFilledForm: function(state){
            return state.cart_filled_form
        },
        getFav: function(state){
            return state.fav;
        },
        getCartEarnPoint: function(state){
            return state.earn_point;
        },
        getNextLevelInfo: function(state){
            return state.next_level_info;
        },
        getRedeemablePoints: function(state){
            return state.redeemable_point;
        },
        getSearchCriteria: function(state){
            return state.search_criteria;
        }
    },
    mutations: {
        changeUserStatus: function(state, payload){
            state.member = payload.member;
            state.is_login = payload.is_login;
        },
        changeCart: function(state, payload){
            state.cart = payload.cart.items;
            state.cart_total_weight = payload.cart.cart.total_weight;
            state.cart_batch_weight = payload.cart.cart.batch_weight;
            state.earn_point = payload.cart.earn_point;
            state.next_level_info = payload.cart.next_level_info;
            state.redeemable_point = payload.cart.redeemable_point;
        },
        changeCartFilledForm: function(state, payload){
            state.cart_filled_form = payload.cart_filled_form
        },
        changeFav: function(state, payload){
            state.fav = payload.fav;
        },
        changeSearchCriteria: function(state, payload){
            state.search_criteria = payload.search_criteria;
        }
    },
    actions: {

    }
});