export default {
    "admin": {
        "tab_title": {
            "slideshows": "Slide Show"
        }
    },
    "en": {
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "frontend": {
            "stock_enough": "24h",
            "stock_less": "1-3d",
            "restock_soon": "Restock",
            "stock_one_left": "3-7d",
            "stock_out_of_stock": "Restock",
            "stock_one_week": "1w",
            "stock_two_week": "2-3w",
            "stock_one_month": "1m",
            "product_description": "Product Description",
            "keyword": "Keyword",
            "register": "Register",
            "login": "Login",
            "register_and_login": "Register/Login",
            "logout": "Logout",
            "favourite_list": "Wish List",
            "account": "Account",
            "cart": "Cart",
            "home": "Home",
            "back": "Back",
            "testimonial": "Testimonial",
            "shop_addr": "Retail Store",
            "news": "News",
            "blog": "Blog",
            "success": "Success",
            "coupon_not_found": "Coupon not found",
            "apply_success": "Coupon redeemed",
            "out_of_limit": "Exceed quantity of available coupon",
            "remove_success": "Coupon removed successfully",
            "games": "Games",
            "household_goods": "Household",
            "digital_goods": "Electronics",
            "boutiques": "Toys & Hobby",
            "mobile_submit_bank_receipt": "Submit payment proof",
            "submit_bank_receipt": "Already submitted payment proof",
            "check_order": "Check order",
            "check_order_input_order_no": "Enter order no.",
            "check_order_submit": "Submit",
            "whatsapp_group_invite_text_short": "Get Secret Offer",
            "whatsapp_group_invite_text": "Get WhatsApp Secret Offer",
            "whatsapp_group_input": "Enter mobile no.",
            "whatsapp_group_confirm": "Submit",
            "whatsapp_group_remark": "Please remember to add 98262852 into your contact list to ensure you will not miss our offer",
            "whatsapp_chat_text": "I am interested in following product(s):",
            "no_state": "No Province/ State",
            "wechat_invite_text": "WeChat ID:",
            "register_username": "Username",
            "register_surname": "Last name",
            "register_givenname": "First name",
            "register_gender": "Gender",
            "register_email": "Email",
            "register_phone": "Mobile no.",
            "register_age_group": "Age Group",
            "register_age_group_0": "Waiting for update",
            "register_age_group_1": "20 year old or below",
            "register_age_group_2": "20 year old - 29 year old",
            "register_age_group_3": "30 year old - 39 year old",
            "register_age_group_4": "40 year old or above",
            "register_address_1": "Address line 1",
            "register_address_2": "Address line 2",
            "register_address_3": "Address line 3",
            "register_address_4": "Address line 4",
            "register_zip_code": "Zip code",
            "register_country": "Country",
            "register_income_group": "Income Group",
            "register_income_group_0": "Waiting for update",
            "register_income_group_1": "USD$660 or below",
            "register_income_group_2": "USD$661 - $1300",
            "register_income_group_3": "USD$1300 - $2000",
            "register_income_group_4": "USD$2001 - $2650",
            "register_income_group_5": "USD$2651 - $3300",
            "register_income_group_6": "USD$3301 or above",
            "register_password": "Password（At least 6 characters）",
            "register_password_confirm": "Re-enter password",
            "register_newsletter": "Subscribe",
            "register_submit": "Submit",
            "register_reset": "Reset",
            "register_confirmation_email_subject": "Membership email confirmation",
            "register_confirmation_email_title": "Hello, {username},",
            "member_gender_male": "Male",
            "member_gender_female": "Female",
            "maintenance_by_bg2": "BuyGame2 Protection Plan",
            "maintenance": "Warranty",
            "member_login": "Login",
            "non_member": "Guest Checkout Gateway",
            "continue_buy": "Continue shopping",
            "non_member_buy": "Guest checkout",
            "member_buy": "Member Checkout Gateway",
            "member_jetso": "Become a member & earn welcome points",
            "instant_register": "Create account",
            "login_criteria": "Login ID/ email/ Tel.",
            "member_email": "Email",
            "member_username": "Login ID",
            "member_tel": "Mobile no.",
            "or": "or",
            "member_password": "Password",
            "member_forgot_password": "Forgot your password?",
            "member_no_account": "Not yet a member?",
            "member_register_immediately": "Register now",
            "member_email_verification": "Member email verification",
            "member_email_verify_message": "Registered successfully！Please verify your email address.",
            "member_email_verified_message": "Email verify completed.You can login now.",
            "member_reset_password": "Forgot password",
            "member_submit_reset_password": "Reset password",
            "member_submit_reset_password_message": "Request for resetting password received! Please check your emai box.",
            "member_new_password": "New password",
            "member_submit_reset_password_finished_message": "Reset of password is done! Please proceed to login again. ",
            "member_level": "Membership Tier",
            "member_page": "Membership Centre",
            "member_policy": "Member Rewards Program",
            "member_greeting": "Hello!",
            "order_info": "Order details",
            "member_order_history": "Order history",
            "member_points": "Points record",
            "member_points_select_year": "Select Year",
            "member_no_records": "No record found",
            "member_point_balance": "Points Balance",
            "member_point_expire": "Points Expire",
            "member_point_expire_message": "About {point} points will be expired in{date}",
            "member_account_information": "My Profile",
            "member_favourite_list": "Wish List",
            "member_coupons": "Coupons",
            "member_order_number": "Order no.",
            "member_purchase_date": "Order date",
            "member_delivery_way": "Delivery Method",
            "member_purchase_status": "Order status",
            "member_delivery_progress": "Delivery Progress",
            "member_delivery_track": "Delivery Tracking",
            "member_track": "Tracking",
            "member_print_order": "Print invoice",
            "member_point_date": "Date",
            "member_point_remarks": "Remarks",
            "member_status_shopping_point": "Points:",
            "member_status_like_count": "no. of like:",
            "member_point_type_1": "Completed order",
            "member_point_type_2": "Like the comment",
            "member_point_type_3": "Comment & Review",
            "member_point_type_4": "Like by others",
            "member_point_type_5": "Redeem points",
            "member_point_type_6": "Points updated by admin",
            "member_point_type_7": "Return",
            "member_point_type_998": "1st Purchase",
            "member_point_type_999": "Register",
            "about_us": "About Us",
            "how_to_purchase": "How to order",
            "contact_us": "Contact Us",
            "support": "Support",
            "terms": "Terms & Conditions",
            "how_to_buy": "How to order",
            "media": "Testimonial",
            "Jan": "JAN",
            "Feb": "FEB",
            "Mar": "MAR",
            "Apr": "APR",
            "May": "MAY",
            "Jun": "JUN",
            "Jul": "JUL",
            "Aug": "AUG",
            "Sep": "SEP",
            "Oct": "OCT",
            "Nov": "NOV",
            "Dec": "DEC",
            "enter_keyword": "Enter keyword",
            "issue_date": "Issue Date",
            "all": "All",
            "read_more": "View more",
            "reserve_product": "Pre-Sale",
            "order_status": "Order status",
            "order_status_desc": "Description",
            "order_status_1": "Completed",
            "order_status_2": "Processing",
            "order_status_3": "Payment reminder",
            "order_status_4": "Canceled",
            "order_status_5": "Confirmed",
            "order_status_6": "Paid/Deposit Received",
            "order_status_7": "Extend",
            "order_status_8": "Ready for pick up",
            "order_status_9": "Waiting for payment",
            "order_status_10": "Pick up reminder",
            "order_status_1_desc": "The order is picked up or delivered. The loyalty points are added to the member's account.",
            "order_status_2_desc": "It will normally take 2 days to finish order processing. You don't need to make any payment at this stage.",
            "order_status_3_desc": "The confirmed order is pending for payment. If we do not receive the payment notification within 3 days, the order will be cancelled automatically.",
            "order_status_4_desc": "The order is cancelled due to incomplete payment / lack of stock / customer's request.",
            "order_status_5_desc": "The order is waiting for payment completion or the proof of payment being uploaded.",
            "order_status_6_desc": "Your credit card is debited / we received the proof of payment uploaded by the customer.",
            "order_status_7_desc": "As some of the item(s) of the order are still under arrangement, the order is still not yet confirmed.",
            "order_status_8_desc": "The order is awaiting for pickup by the customer. The goods will be kept for 14 days and will be discarded after this period.",
            "order_status_9_desc": "The proof of payment has not been uploaded or the credit card payment did not go through",
            "order_status_10_desc": "The pickup period of the goods has passed. If the goods is not picked up in 3 days, it will be discarded and no refund is allowed.",
            "whatsapp_notice": "Notice from BuyGame2: Your order ({ref_no}) is ready now. Please come to pick up at our retail shop within 14 days. Here's our shop's address: Room 402-404, Cheung Fat Commercial Building, 266-268 Cheung Sha Wan Road, Sham Shui Po. The order will be seen as ineffective if the pick up date is overdue.",
            "whatsapp_notice_mtr": "Notice from BuyGame2: Your order ({ref_no}) is ready now. Please with us for to arrange the pick up time. Here's the meeting point of fast trade location: Gate near Exit C, D at Sham Shui Po MTR Station. The order will be seen as ineffective if the pick up date is overdue.",
            "all_products": "All",
            "wednesday_crazy_sales_area": "Friday's Crazy Sale",
            "preorder_rank": "Pre-order Ranking",
            "weekly_top_50": "Weekly Top 50",
            "youtube": "Youtube Channel",
            "limited_time_sales_area": "Limited Time Sales",
            "popular_consoles": "Hot platform",
            "hot_items_area": "Hot Items",
            "new_arrivals_area": "New arrivals",
            "restocked_area": "Restock",
            "recommendation_area": "Recommendation",
            "preorder_area": "Pre-order",
            "sales_area": "Sales",
            "clearance_area": "Clearance",
            "second_hands_area": "Pre-owned Zone",
            "second_hands": "Pre-owned",
            "other_ppls_choice": "Customers who bought this also bought",
            "your_favourites": "Guess you like",
            "browse_history": "Recently viewed",
            "related_products": "Related to items you've viewed",
            "checkout_now": "Get it now",
            "product_category": "Product Category",
            "shop_info": "Shop Info",
            "settings": "Settings",
            "input": "Enter",
            "search": "Search",
            "search_reset": "Reset",
            "search_placeholder_gamename": "Name",
            "select_product_type": "Genre",
            "product_search_input": "Enter",
            "site_wide_search": "Search Whole Site",
            "advanced_search": "Advance search",
            "hot_search": "Hot search",
            "search_result": "Search Result",
            "more_result": "More result",
            "search_additional_rules": "Additional Rules",
            "search_sorting_criteria": "Sorting",
            "search_arrival_date_placeholder": "ddmmyy - ddmmyy",
            "search_input_placeholder": "Enter here...",
            "search_criteria_newest_sales": "Newest sales",
            "search_criteria_buy_from_shop": "Available in Store",
            "search_criteria_sales_price": "Sales",
            "search_criteria_sort_A-Z": "Asce A-Z",
            "search_criteria_sort_Z-A": "Desc Z-A",
            "search_criteria_create_date": "Released date",
            "search_criteria_product_price": "Price Max to Min",
            "showing_console_type": "Console Type: {type}",
            "product_all": "All",
            "product_type_all": "All",
            "product_type_more": "More Products",
            "product_search_results": "Search Result",
            "product_search_results_unit": "item(s)",
            "product_show": "Showing",
            "product_search_default": "Default",
            "products_no": "Product code",
            "products_name": "Name",
            "products_console": "Platform",
            "products_type": "Catalog",
            "products_genre": "Genre",
            "products_version": "Version",
            "products_language": "Language",
            "products_dialogue_language": "Subtitles",
            "products_audio_language": "Voice",
            "products_stock_label": "Availability",
            "products_players_count": "No. of players",
            "products_players_online": "Online",
            "products_players_offline": "Offline",
            "products_number_player_online": "Online",
            "products_number_player_offline": "Offline",
            "products_origin": "Origin",
            "products_video_compatibility": "Compatibility",
            "products_no_protection": "No protection",
            "products_reward_member_point": "Earn Points",
            "products_arrival_date": "Arrival Date",
            "products_product_stock_status": "Stock Availability",
            "products_refer_link": "Reference site",
            "products_youtube": "Trailer",
            "products_click_here": "Click Here",
            "products_origin_name": "Origin Name",
            "products_model_no": "Model No.",
            "products_pre_sales_price": "Original Price",
            "products_sales_price": "Sales Price: ",
            "products_price": "Price",
            "products_point": "Point",
            "products_discount_saving": "Saving ",
            "products_details": "Details",
            "products_redemption_discounts": "Redmption discount",
            "products_add_value_to_redeem": "Add {value_and_dollar_sign} to redeem",
            "products_add_to_cart": "Add to cart",
            "products_add_to_favourite": "Add to wish list",
            "products_share": "Share",
            "products_buy_at_store": "*Available in Physical Store as well",
            "products_oversea": "*Provide Direct Shipment from our U.S. warehouses",
            "oversea_warehouse": "*Direct Shipment from our U.S. warehouses",
            "products_digital_goods_only": "*Delivery via email",
            "products_sell_online_only": "*Available in Online Shop only",
            "products_allow_pre_order": "Pre-order Now",
            "products_customer_review": "Customers reviews",
            "prodcuts_customer_review_success": "Comment successfully",
            "products_customer_review_like_success": "You've liked this review",
            "products_customer_review_like_failed": "Error occured, please try again later",
            "products_customer_review_like_duplicate": "You've wrote a review",
            "products_rating_avg": "Average",
            "products_create_review": "Write a customer review",
            "products_message_placeholder": "Please enter…",
            "products_rating_gameplay": "Gameplay:",
            "products_rating_graphics": "Graphic:",
            "products_rating_sound": "Sound Effect:",
            "products_rating_lasting_appeal": "Recommendation",
            "please_enter": "Please enter…",
            "validate": "to validate",
            "currency": "Currency",
            "products_group_discount": "Group Discount",
            "products_company_discount": "Procurement",
            "products_quotation_for_wholesale": "Wholesale",
            "products_request_2nd_hand": "Sell your preowned",
            "products_ask_2nd_hand": "Request for preowned",
            "out_of_stock_subscribe": "Restock Alert",
            "products_price_change_notifications": "Price change Alert",
            "products_number_players_0": "N/A",
            "products_number_players_1": "1",
            "products_number_players_2": "1-2",
            "products_number_players_3": "1-3",
            "products_number_players_4": "1-4",
            "products_number_players_5": "1-5",
            "products_number_players_6": "1-6",
            "products_number_players_7": "1-8",
            "products_number_players_10": "10+",
            "sales_time_countdown_format": "d day(s) h hour(s) mm min(s) ss second(s)",
            "sales_time_left": "Time left",
            "sales_quota_left": "Quota left",
            "sales_expired": "Offer has ended",
            "upload_bankslip": "Submit Payment Proof",
            "submitting": "Submitting, please wait...",
            "product_ranking_more": "View more",
            "general_product_area_empty": "No product found",
            "history_empty": "Order history empty",
            "products_stock_all": "All",
            "products_stock_1": "Enough",
            "products_stock_2": "Less",
            "products_stock_3": "Restock",
            "products_stock_4": "Only 1 left",
            "products_stock_5": "Sold out",
            "products_stock_label_to_client_1": "Ship in around 24 hours",
            "products_stock_label_to_client_2": "Ship in around 1-3 days",
            "products_stock_label_to_client_3": "Restock",
            "products_stock_label_to_client_4": "Ship in around 3-7 days",
            "products_stock_label_to_client_5": "Restock",
            "products_stock_label_to_client_6": "Ship in around 1 week",
            "products_stock_label_to_client_7": "Ship in around 2-3 week",
            "products_stock_label_to_client_8": "Ship in around 1 month",
            "check_order_statement": "Please check if the order is correct and then upload the payment record / receipt.",
            "cart_postal_code_not_found": "Please enter the correct postal code",
            "cart_dhl_error": "DHL API Error",
            "cart_aramex_error": "Aramex API Error",
            "cart_dhl_insurance": "Insurance (DHL)",
            "cart_error_contact_support": "Sorry, we are temporarily unable to process your order, please try again later or contact customer service",
            "cart_contact_support": "Sorry, the total weight of the products has exceeded the valid range. Please adjust and try again.",
            "cart_complete_order_can_earn": "Complete this order to earn",
            "cart_grade_need": "Remain",
            "cart_grade_need_message": "Cumulative consumption, you will tier upgrade to ({grade_name})",
            "cart_member": "Member",
            "cart_product": "Product",
            "cart_quantity": "Quantity",
            "cart_price": "Price",
            "cart_total": "Total",
            "cart_added_value_to_redeem": "Add{value_and_dollar_sign} to redeem",
            "cart_remove_item": "Delete",
            "cart_move_to_fav": "Save for later",
            "cart_estimated_ship_date": "Estimated shipment date:",
            "cart_extracharges": "Add-value services",
            "cart_extracharges_optional": "",
            "cart_point_redemption_discount": "Points redemption",
            "cart_coupon_code": "Coupon",
            "cart_validate_coupon_code": "Validate",
            "cart_shipment_information": "Shipment details",
            "cart_shipment_method": "Delivery method",
            "cart_shipment_country_area": "Country/ Area",
            "cart_recipient_information": "Recipient Info",
            "cart_recipient_name": "Name",
            "cart_recipient_email": "email",
            "cart_recipient_tel": "Mobile no.:",
            "cart_delivery_address": "Delivery address (Line 1)",
            "cart_delivery_address_2": "Delivery address (Line 2)",
            "cart_delivery_address_3": "Delivery address (Line 3)",
            "cart_city": "City",
            "cart_state": "Province/ State",
            "cart_postal_code": "Postal code",
            "cart_payment_method": "Payment method",
            "cart_consolidation": "Ship all together",
            "cart_remarks": "Remarks",
            "cart_sub_total": "Sub-total:",
            "cart_shipment_fee": "Shipping Fee:",
            "cart_paypal_extra_charge": "Payment handling charge:",
            "cart_payment_extra_charge": "Payment handling charge:",
            "cart_oversea_extra_charge": "海外倉運費：",
            "cart_zeek_locker": "Locker Code:",
            "cart_grand_total": "Order total:",
            "cart_redeem_point": "Redeem points:",
            "cart_redeem_coupon": "Redeem coupon{name}",
            "cart_discount": "Discount",
            "cart_estimated_shipment_date_same": "Estimated delivery： {date}",
            "cart_estimated_shipment_date_diff": "Estimated delivery： {date_from} to {date_to}",
            "cart_estimated_batch_shipment_date": "预计送货日期： ",
            "cart_estimated_batch_shipment_date_diff": "Estimated delivery ({lot} shipment)： {date_from} to {date_to}",
            "cart_estimated_batch_shipment_date_same": "Estimated delivery ({lot} shipment)： {date}",
            "estimate_delivery_caution": "Shipment date will be delayed due to pre-order item included",
            "cart_confirm_order_and_address": "I confirmed the order and address are correct.",
            "cart_declared_value_for_custom": "Declared Customs Value",
            "cart_suggested_declared_value_for_custom": "Suggested declared Customs value",
            "cart_continue_shopping": "Continue shopping",
            "cart_checkout_confirm": "Place order",
            "cart_empty": "Empty cart, please click to shop now",
            "cart_updated": "Cart updated",
            "cart_please_pick": "Please choose",
            "remove_discount": "Remove discount",
            "cart_district": "District",
            "cart_area": "Area",
            "cart_delivery_store_code": "Store Code",
            "cart_delivery_store_code_enter": "Please insert SF Stores code",
            "cart_delivery_store_code_post": "Post Office Counter",
            "cart_delivery_store_code_ipost": "iPostal Station",
            "cart_delivery_store_code_post_enter": "Please insert name of Post Office/iPostal station code",
            "cart_calculate_shipment_title": "Calculating postage",
            "cart_calculate_shipment_content": "Calculating postage...",
            "cart_delivery_city_is_null": "City cannot be null",
            "cart_declared_value_for_custom_is_null": "Declared Customs Value cannot be null",
            "cart_delivery_postal_code_is_null": "Postal code cannot be null",
            "cart_remove_out_of_stock": "Since this item is shortage already, we removed from shopping cart for you.",
            "delivery_method_is_null": "Delivery method must be select",
            "address_is_null": "Address cannot be null",
            "name_is_null": "Name cannot be null",
            "tel_is_null": "Mobile no. cannot be null",
            "email_is_null": "Email cannot be null",
            "delivery_store_code_is_null": "Store Code cannot be null",
            "payment_method_is_null": "Payment method must be select",
            "confirm_order_and_address_must_tick": "Please check the \"I confirmed the order and address are correctly.\" checkbox",
            "zeeK_locker_code": "<p>Supplier: {type}<br>Name: {name}<br>Code: {code}<br>Address: {address}<br>Opening hours: {opening_hour}</p>",
            "fav_product": "Product",
            "fav_quantity": "Quantity",
            "fav_price": "Price",
            "fav_total": "Total",
            "fav_remove_item": "Delete",
            "fav_move_to_cart": "Add to cart",
            "fav_estimated_ship_date": "Estimate shipment date",
            "fav_empty": "Empty wishlist, Please click to shop now",
            "fav_updated": "Wishlist updated",
            "order_history_empty": "Empty order record. Please click to shop now",
            "order_not_found": "Order not found. Please click to shop now",
            "order_not_confirmed": "Your order is in processing. Please wait for our confirmation email.",
            "order_paid": "Proof of payment have been uploaded successfully.",
            "file_size_limit": "Your file is oversized. Please upload the file below 5MB.",
            "file_size_limit_text": "Size Limit: below 5MB.",
            "view_file": "View PDF file",
            "order_no": "Order no.",
            "order_offline_payment": "Payment proof",
            "order_upload_offline_payment": "Upload payment proof",
            "order_offline_payment_upload_time": "Uploaded time",
            "order_offline_payment_upload_file": "Uploaded file",
            "order_offline_payment_file": "File",
            "order_offline_payment_remarks": "Remark",
            "order_change": "Changing order",
            "order_cancel": "Canceling order",
            "order_view": "Reviewing order",
            "order_change_request": "Changing order details",
            "order_submit_change": "Change / Cancel order",
            "order_change_request_type": "Type of order changed",
            "order_change_request_request": "Request described",
            "order_change_request_reply": "Reply by our Customer Service Team",
            "order_change_request_status": "Status",
            "order_change_request_type_1": "Update Order",
            "order_change_request_type_2": "Cancel Order",
            "order_change_request_status_1": "Processing",
            "order_change_request_status_2": "Accept",
            "order_change_request_status_3": "Decline",
            "order_comment": "Ratings & Reviews",
            "order_comment_comment": "Comment",
            "order_comment_reply": "Reply by customer service team",
            "order_comment_status": "Status",
            "order_comment_status_1": "Submited",
            "order_comment_status_2": "Reviewed",
            "payment_not_net_settle_comment": "Proof of payment has not received",
            "invoice": "Invoice",
            "invoice_name": "Name",
            "invoice_email": "Email",
            "invoice_tel": "Mobile no.:",
            "invoice_payment_method": "Payment method",
            "invoice_delievey_method": "Delivery method",
            "invoice_delievey_address": "Delivery address",
            "invoice_postal_code": "Postal code",
            "invoice_remark": "Remark",
            "invoice_remark_buygame2": "Shop Remark",
            "invoice_declared_value_for_custom": "Declared value (Custom)",
            "invoice_country": "Country",
            "invoice_state": "Province/ State",
            "invoice_city": "City",
            "invoice_product_name": "Product name",
            "invoice_category": "Category",
            "invoice_version": "Version",
            "invoice_unit_price": "Unit Price",
            "invoice_quantity": "Quantity",
            "invoice_subtotal": "Subtotal",
            "invoice_delievery_charge": "Shipping Fee",
            "invoice_service_charge": "Payment Handling fee",
            "invoice_extra_charge": "Added-on services",
            "invoice_discount": "Cash Rebate",
            "invoice_grand_total": "Order total",
            "invoice_used_point": "Redeemed points",
            "point": "Points",
            "point_full": "Points",
            "message_page_expired": "Page not found. Please back to HOME",
            "all_right_reserved_term": "Buy Game 2.com. All rights reserved.",
            "network_error": "Network Error",
            "please_retry_later": "Try again later",
            "order_no_records": "No record found。",
            "share": "Share to friends",
            "group_buy": "Group Discount",
            "company_buy": "Company procument",
            "wholesale": "Wholesale quotation",
            "2nd_hand_buy": "Look for pre-owned",
            "2nd_hand_sell": "Sell your pre-owned",
            "price_change": "Price change Alert",
            "waiting_restock": "Restock Alert",
            "products_2nd_hand": "Pre-owned",
            "products_pre_order": "Pre-order",
            "products_restock": "Products restock registration",
            "battery_included": "Battery included alert",
            "update_cart": "Since you have updated your shopping cart, please re-select the shipping method.",
            "contact_info": "Contact",
            "contact_address": "Address:",
            "email_normal_enquiry": "email（General enquiry）:",
            "whatsapp": "Whatsapp（General enquiry）:",
            "email_collaborate": "email (For collaboration)",
            "skype": "Skype:",
            "facebook": "Facebook:",
            "instagram": "Instagram:",
            "twitter": "Twitter:",
            "weibo": "Weibo:",
            "reason": "Reason: {reason}",
            "enquiry_type": "Types of Enquiry",
            "enquiry_type_1": "Product issues",
            "enquiry_type_2": "Enterprise Procument",
            "enquiry_type_3": "Look for pre-owned",
            "enquiry_type_4": "Free consignment",
            "enquiry_type_5": "General enquiries",
            "enquiry_type_6": "Group Discount",
            "enquiry_type_7": "Wholesale quotation",
            "enquiry_type_8": "Change/ Cancel order",
            "enquiry_type_9": "Tracking",
            "enquiry_type_10": "Payment notification",
            "enquiry_type_11": "Complaint / Suggestion",
            "enquiry_type_12": "Pre-order alert",
            "enquiry_type_13": "Restock alert",
            "enquiry_type_14": "Enterprise Procument",
            "enquiry_urgent": "Urgent Enquiry:",
            "enquiry_urgent_yes": "Yes",
            "enquiry_name": "Name:",
            "enquiry_email": "email:",
            "enquiry_tel": "Mobile no.:",
            "enquiry_country": "Country:",
            "enquiry_country_select": "Please select",
            "enquiry_title": "Enquiry theme:",
            "enquiry_title_select": "Please select",
            "internal_remark": "Shop remarks:",
            "enquiry_1_order_no": "Order No.:",
            "enquiry_1_rma_reason": "Problems:",
            "enquiry_2_product_name_or_no": "Product name/ Product code.",
            "enquiry_2_product_name_or_no$1": "Product name/ Product code.",
            "enquiry_2_product_name_or_no$2": "Product name/ Product code.",
            "enquiry_2_product_name_or_no$3": "Product name/ Product code.",
            "enquiry_2_product_name_or_no$4": "Product name/ Product code.",
            "enquiry_2_product_name_or_no$5": "Product name/ Product code.",
            "enquiry_2_supply_quantity$1": "Quantity:",
            "enquiry_2_supply_quantity$2": "Quantity:",
            "enquiry_2_supply_quantity$3": "Quantity:",
            "enquiry_2_supply_quantity$4": "Quantity:",
            "enquiry_2_supply_quantity$5": "Quantity:",
            "enquiry_2_company_name": "Company Name:",
            "enquiry_3_product_name_or_no$1": "Product name/ Product code.",
            "enquiry_3_budget$1": "Expected budget:",
            "enquiry_3_need_dlc$1": "Need DLC:",
            "enquiry_3_product_name_or_no$2": "Product name/ Product code.",
            "enquiry_3_budget$2": "Expected budget:",
            "enquiry_3_need_dlc$2": "Need DLC:",
            "enquiry_3_product_name_or_no$3": "Product name/ Product code.",
            "enquiry_3_budget$3": "Expected budget:",
            "enquiry_3_need_dlc$3": "Need DLC:",
            "enquiry_3_product_name_or_no$4": "Product name/ Product code.",
            "enquiry_3_budget$4": "Expected budget:",
            "enquiry_3_need_dlc$4": "Need DLC:",
            "enquiry_3_product_name_or_no$5": "Product name/ Product code.",
            "enquiry_3_budget$5": "Expected budget:",
            "enquiry_3_need_dlc$5": "Need DLC:",
            "enquiry_3_acceptable_prepare_time$1": "Acceptable waiting time:",
            "enquiry_3_acceptable_prepare_time$2": "Acceptable waiting time:",
            "enquiry_3_acceptable_prepare_time$3": "Acceptable waiting time:",
            "enquiry_3_acceptable_prepare_time$4": "Acceptable waiting time:",
            "enquiry_3_acceptable_prepare_time$5": "Acceptable waiting time:",
            "enquiry_4_product_name_or_no$1": "Product name/ Product code.",
            "enquiry_4_supply_price$1": "Selling price:",
            "enquiry_4_supply_quantity$1": "Quantity:",
            "enquiry_4_product_name_or_no$2": "Product name/ Product code.",
            "enquiry_4_supply_price$2": "Selling price:",
            "enquiry_4_supply_quantity$2": "Quantity:",
            "enquiry_4_product_name_or_no$3": "Product name/ Product code.",
            "enquiry_4_supply_price$3": "Selling price:",
            "enquiry_4_supply_quantity$3": "Quantity:",
            "enquiry_4_product_name_or_no$4": "Product name/ Product code.",
            "enquiry_4_supply_price$4": "Selling price:",
            "enquiry_4_supply_quantity$4": "Quantity:",
            "enquiry_4_product_name_or_no$5": "Product name/ Product code.",
            "enquiry_4_supply_price$5": "Selling price:",
            "enquiry_4_supply_quantity$5": "Quantity:",
            "enquiry_5_normal_content": "Content:",
            "enquiry_6_product_name_or_no": "Product name/ Product code.",
            "enquiry_6_budget": "Expected budget:",
            "enquiry_6_quantity": "Quantity:",
            "enquiry_7_product_name_or_no": "Product name/ Product code.",
            "enquiry_7_product_name_or_no$1": "Product name/ Product code.",
            "enquiry_7_product_name_or_no$2": "Product name/ Product code.",
            "enquiry_7_product_name_or_no$3": "Product name/ Product code.",
            "enquiry_7_product_name_or_no$4": "Product name/ Product code.",
            "enquiry_7_product_name_or_no$5": "Product name/ Product code.",
            "enquiry_7_supply_quantity": "Quantity:",
            "enquiry_7_supply_quantity$1": "Quantity:",
            "enquiry_7_supply_quantity$2": "Quantity:",
            "enquiry_7_supply_quantity$3": "Quantity:",
            "enquiry_7_supply_quantity$4": "Quantity:",
            "enquiry_7_supply_quantity$5": "Quantity:",
            "enquiry_7_country_of_product_origin": "Product origin country:",
            "enquiry_7_company_name": "Company Name:",
            "enquiry_8_order_no": "Order no.:",
            "enquiry_8_order_change_reason": "Changing/ Cancellation request details:",
            "enquiry_9_order_no": "Order no.:",
            "enquiry_9_delivery_method": "Delivery Method:",
            "enquiry_10_offline_payment_receipt": "Payment proof:",
            "enquiry_10_offline_payment_bank": "Bank of transfer:",
            "enquiry_10_offline_payment_time": "Time of transfer:",
            "enquiry_10_offline_payment_amount": "Amount of transfer:",
            "enquiry_11_content": "Remark:",
            "enquiry_12_product_name_or_no": "Product name/ Product code.",
            "enquiry_13_product_name_or_no": "Product name/ Product code.",
            "enquiry_14_company_name": "Enterprise name:",
            "enquiry_14_company_contact": "Email:",
            "enquiry_14_purchase_content": "Purchase content:",
            "enquiry_other": "Other:",
            "enquiry_submit": "Submit",
            "enquiry_submit_title": "Enquiry Form",
            "enquiry_submit_message": "You have submitted your enquiry, we will get back you soon.",
            "share_friend": "Share",
            "share_friend_email": "Friends {count} email:",
            "share_submit_message": "You've shared to your friend successfully!",
            "share_message_title": "Your friend recommend this product to you",
            "share_message_part1": "Hello!",
            "share_message_part2": "Your friend {enquiry_name} recommend this treasure product from BuyGame2 to you {product_name_link}。",
            "share_message_part3": "Please click {product_link} to get more details！",
            "share_message_part4": "BuyGame2.com",
            "out_of_stock_subscribe_success": "We have registered for you successfully. Once this product be restocked, we will email to inform you immediately. Please pay attention to your email box.",
            "out_of_stock_subscribe_success2": "Warm reminder: Please remember to add our email to your white list so as to prevent our email from being dropped into Junk or Spam folder and miss our notification",
            "bg2_system": "Admin Panel",
            "bg2_dot_com": "Buy Game 2.com"
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "passwords": {
            "password": "Passwords must be at least six characters and match the confirmation.",
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "confirm_order_and_address": "Confirm Order and Address",
                "offline_payment_receipt": "Payment proof"
            }
        }
    },
    "zh-cn": {
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "frontend": {
            "stock_enough": "24小时",
            "stock_less": "1-3天",
            "restock_soon": "补货中",
            "stock_one_left": "3-7天",
            "stock_out_of_stock": "補貨中",
            "stock_one_week": "1星期",
            "stock_two_week": "2-3星期",
            "stock_one_month": "1个月",
            "product_description": "产品描述",
            "keyword": "关键词",
            "register": "注册",
            "login": "登入",
            "register_and_login": "注册/登入",
            "logout": "登出",
            "favourite_list": "喜爱清单",
            "account": "账户",
            "cart": "购物车",
            "home": "首页",
            "back": "返回",
            "testimonial": "传媒报导",
            "news": "最新消息",
            "shop_addr": "门市资料",
            "blog": "博客",
            "success": "成功",
            "coupon_not_found": "找不到优惠劵",
            "apply_success": "成功使用优惠劵",
            "out_of_limit": "优惠劵已超出可用数量",
            "remove_success": "成功移除优惠劵",
            "games": "电玩",
            "household_goods": "生活百货",
            "digital_goods": "数码影音",
            "boutiques": "玩具精品",
            "mobile_submit_bank_receipt": "提交付款证明",
            "submit_bank_receipt": "已提交付款证明",
            "check_order": "查看订单状态",
            "check_order_input_order_no": "输入订单编号",
            "check_order_submit": "提交",
            "whatsapp_group_invite_text_short": "接收神秘优惠",
            "whatsapp_group_invite_text": "接收WhatsApp神秘优惠",
            "whatsapp_group_input": "输入手提电话",
            "whatsapp_group_confirm": "提交",
            "whatsapp_group_remark": "請記得把98262852也加入您的通訊錄，才能成功接收到優惠",
            "whatsapp_chat_text": "我有兴趣买您的货品:",
            "no_state": "沒有州/省",
            "wechat_invite_text": "官方微信账号：：",
            "register_username": "用户名称",
            "register_surname": "姓",
            "register_givenname": "名",
            "register_gender": "性别",
            "register_email": "电邮",
            "register_phone": "手提电话：",
            "register_age_group": "年龄组别",
            "register_age_group_0": "待更新",
            "register_age_group_1": "20 岁以下",
            "register_age_group_2": "20 岁 - 29 岁",
            "register_age_group_3": "30 岁 - 39 岁",
            "register_age_group_4": "40 岁或以上",
            "register_address_1": "地址1",
            "register_address_2": "地址2",
            "register_address_3": "地址3",
            "register_address_4": "地址4",
            "register_zip_code": "邮政编号",
            "register_country": "国家",
            "register_income_group": "入息范围",
            "register_income_group_0": "待更新",
            "register_income_group_1": "RMB 5,000 或以下",
            "register_income_group_2": "RMB 5,001 - $10,000",
            "register_income_group_3": "RMB 10,001 - $15,000",
            "register_income_group_4": "RMB 15,001 - $20,000",
            "register_income_group_5": "RMB 20,001 - $25,000",
            "register_income_group_6": "RMB 25,000 或以上",
            "register_password": "密码（最少6个字）",
            "register_password_confirm": "重新输入密码",
            "register_newsletter": "我想订阅着数情报",
            "register_submit": "提交",
            "register_reset": "重设",
            "register_confirmation_email_subject": "会员申请电邮确认",
            "register_confirmation_email_title": "您好, {username}",
            "member_gender_male": "男",
            "member_gender_female": "女",
            "maintenance_by_bg2": "买Game2保养",
            "maintenance": "保养",
            "member_login": "会员登入",
            "non_member": "访客购物通道",
            "continue_buy": "继续购买",
            "non_member_buy": "访客单次直购",
            "member_buy": "会员购物通道",
            "member_jetso": "成为会员即赚迎新积分",
            "instant_register": "注册成为会员",
            "login_criteria": "登入名称/ 电邮 / 电话",
            "member_email": "电邮",
            "member_username": "登入名称",
            "member_tel": "行动电话：",
            "or": "或",
            "member_password": "密码",
            "member_forgot_password": "忘记密码?",
            "member_no_account": "未成为会员?",
            "member_register_immediately": "马上注册!",
            "member_email_verification": "会员电邮验证",
            "member_email_verify_message": "您已成功注册！请验证您的电邮。",
            "member_email_verified_message": "会员电邮验证完成！你已可以进入登入。",
            "member_reset_password": "忘记密码",
            "member_submit_reset_password": "重设密码",
            "member_submit_reset_password_message": "已收到你重设密码的要求！请检查您的电邮。",
            "member_new_password": "新密码",
            "member_submit_reset_password_finished_message": "已完成重设密码！你可再次尝试登入。",
            "member_level": "会员级别",
            "member_page": "会员中心",
            "member_policy": "会员奖赏计划",
            "member_greeting": "亲, 您好!",
            "order_info": "订单信息",
            "member_order_history": "购买记录",
            "member_points": "积分记录",
            "member_points_select_year": "请选择年份",
            "member_no_records": "没有相关记录",
            "member_point_balance": "积分结余",
            "member_point_expire": "积分到期",
            "member_point_expire_message": "有{point}分将于{date}到期",
            "member_account_information": "个人资料",
            "member_favourite_list": "喜爱清单",
            "member_coupons": "电子优惠券",
            "member_order_number": "订单编号",
            "member_purchase_date": "订购日期",
            "member_delivery_way": "送货方式",
            "member_purchase_status": "订购狀态",
            "member_delivery_progress": "货运完成度",
            "member_delivery_track": "追踪运送情况",
            "member_track": "追踪",
            "member_print_order": "打印订单",
            "member_point_date": "日期",
            "member_point_remarks": "备注",
            "member_status_shopping_point": "现有积分：",
            "member_status_like_count": "被赞好次数：",
            "member_point_type_1": "完成订单",
            "member_point_type_2": "赞好他人留言",
            "member_point_type_3": "对产品评价及留言",
            "member_point_type_4": "被他人赞好",
            "member_point_type_5": "使用积分",
            "member_point_type_6": "由管理员变更的积分",
            "member_point_type_7": "退货",
            "member_point_type_998": "首次购物",
            "member_point_type_999": "登记会员",
            "about_us": "关于我们",
            "how_to_purchase": "如何购买",
            "contact_us": "联络我们",
            "support": "条款及细则",
            "terms": "如何购买",
            "how_to_buy": "传媒报导",
            "media": "一月",
            "Jan": "二月",
            "Feb": "三月",
            "Mar": "四月",
            "Apr": "五月",
            "May": "六月",
            "Jun": "七月",
            "Jul": "八月",
            "Aug": "九月",
            "Sep": "十月",
            "Oct": "十一月",
            "Nov": "十二月",
            "Dec": "输入关键词",
            "enter_keyword": "输入关键词",
            "issue_date": "发布日期",
            "all": "全部",
            "read_more": "查看更多",
            "reserve_product": "预约货品",
            "order_status": "订购状态",
            "order_status_desc": "说明",
            "order_status_1": "已完成",
            "order_status_2": "处理中",
            "order_status_3": "付款提醒",
            "order_status_4": "已取消",
            "order_status_5": "已确认",
            "order_status_6": "已付款/已落订",
            "order_status_7": "廷长处理",
            "order_status_8": "可供提货",
            "order_status_9": "待付款",
            "order_status_10": "取货提醒",
            "order_status_1_desc": "订单已被提取或寄出，购物积分已加至会员户口",
            "order_status_2_desc": "订单一般在2个工作天内完成处理，现阶段无需付款",
            "order_status_3_desc": "已结束之订单仍在等待客户付款中，若3次仍未未收到付款通知，订单将会自动被取消",
            "order_status_4_desc": "订单因未付款/ 缺货/ 客户要求而已被取消",
            "order_status_5_desc": "订单正等待客户付款或上载付款证明",
            "order_status_6_desc": "信用咭已转账 或 已收到客户上载之付款证明",
            "order_status_7_desc": "由于订单中的部分货件仍在努力安排中，故未能落实订单",
            "order_status_8_desc": "订单正等待客户取货，货件保留14天后会被弃置",
            "order_status_9_desc": "仍未收到客户上载之付款证明 或 信用咭无法转账",
            "order_status_10_desc": "货件已超过可提货期限，若3天内仍未取货，将会被弃置，款项亦不能退回",
            "whatsapp_notice": "買Game2通知: 您的訂單({ref_no})已到貨，請於14天內到深水埗長沙灣道266-268號，昌發商業大廈 402-404室 BuyGame2門市取貨，逾期無效",
            "whatsapp_notice_mtr": "買Game2通知: 您的訂單({ref_no})已到貨，請回覆我們相約在14天內，於深水埗地鐵站C/ D出閘口交收，逾期無效",
            "all_products": "全部货品",
            "wednesday_crazy_sales_area": "星期五限量秒杀区",
            "preorder_rank": "预订排行榜",
            "weekly_top_50": "每周Top 50",
            "youtube": "Youtube频道",
            "limited_time_sales_area": "限时优惠区",
            "popular_consoles": "热门游戏主机",
            "hot_items_area": "热卖区",
            "new_arrivals_area": "最新上架区",
            "restocked_area": "再补货区",
            "recommendation_area": "热门推介区",
            "preorder_area": "最新预约区",
            "sales_area": "最新减价区",
            "clearance_area": "平价特卖场",
            "second_hands_area": "二手货区",
            "second_hands": "二手货品",
            "other_ppls_choice": "其他用户也购买",
            "your_favourites": "猜你喜欢",
            "browse_history": "最近浏览过的商品",
            "related_products": "相关产品",
            "checkout_now": "立即购买",
            "product_category": "产品分类",
            "shop_info": "商店资讯",
            "settings": "设定",
            "input": "输入",
            "search": "搜寻",
            "search_reset": "重设",
            "search_placeholder_gamename": "游戏名称",
            "select_product_type": "选择分类",
            "product_search_input": "输入",
            "site_wide_search": "全站搜寻",
            "advanced_search": "进阶搜寻",
            "hot_search": "热门搜寻",
            "search_result": "搜寻结果",
            "more_result": "更多结果",
            "search_additional_rules": "其它条件",
            "search_sorting_criteria": "商品排序",
            "search_arrival_date_placeholder": "日月年 - 日月年",
            "search_input_placeholder": "请在此输入...",
            "search_criteria_newest_sales": "最新减价",
            "search_criteria_buy_from_shop": "门市直购",
            "search_criteria_sales_price": "优惠价格",
            "search_criteria_sort_A-Z": "顺序 A-Z",
            "search_criteria_sort_Z-A": "倒序 Z-A",
            "search_criteria_create_date": "上架时间",
            "search_criteria_product_price": "价格由高至低",
            "showing_console_type": "显示机种：{type}",
            "product_all": "所有",
            "product_type_all": "所有",
            "product_type_more": "更多产品",
            "product_search_results": "搜寻结果",
            "product_search_results_unit": "个",
            "product_show": "显示",
            "product_search_default": "预设",
            "products_no": "产品编号",
            "products_name": "名称",
            "products_console": "机种",
            "products_type": "类别",
            "products_genre": "游戏种类",
            "products_version": "版本",
            "products_language": "语言",
            "products_dialogue_language": "字幕",
            "products_audio_language": "语音",
            "products_stock_label": "货件狀态",
            "products_players_count": "游玩人数",
            "products_players_online": "在线",
            "products_players_offline": "离线",
            "products_number_player_online": "在线人数",
            "products_number_player_offline": "离线人数",
            "products_origin": "来源地",
            "products_video_compatibility": "兼容性",
            "products_no_protection": "没有区码保护",
            "products_reward_member_point": "购物积分",
            "products_arrival_date": "推出日期",
            "products_product_stock_status": "库存状况",
            "products_refer_link": "参考网址",
            "products_youtube": "视频",
            "products_click_here": "请按此",
            "products_origin_name": "原名",
            "products_model_no": "型号",
            "products_pre_sales_price": "原价",
            "products_sales_price": "现价",
            "products_price": "价格",
            "products_point": "积分",
            "products_discount_saving": "节省",
            "products_details": "详细资料",
            "products_redemption_discounts": "换购优惠",
            "products_add_value_to_redeem": "加{value_and_dollar_sign}可换购",
            "products_add_to_cart": "加入购物车",
            "products_add_to_favourite": "加入喜爱清单",
            "products_share": "分享",
            "products_buy_at_store": "*同时提供门市直购",
            "oversea_warehouse": "*美国仓直接发货",
            "products_oversea": "*支持由我们的美国仓直接发货",
            "products_digital_goods_only": "*经电邮传送",
            "products_sell_online_only": "*只供网上订购",
            "products_allow_pre_order": "接受预订中",
            "products_customer_review": "客户评价",
            "prodcuts_customer_review_success": "你已成功评价。",
            "products_customer_review_like_success": "你已成功对其他客户的留言赞好。",
            "products_customer_review_like_failed": "网络错误，请稍后再试。",
            "products_customer_review_like_duplicate": "你已对此产品作出评价。",
            "products_rating_avg": "平均分",
            "products_create_review": "我要评分/留言",
            "products_message_placeholder": "请输入内容…",
            "products_rating_gameplay": "好玩程度：",
            "products_rating_graphics": "画面：",
            "products_rating_sound": "音效：",
            "products_rating_lasting_appeal": "推介程度：",
            "please_enter": "请输入",
            "validate": "作验证",
            "currency": "货币",
            "products_group_discount": "团购折扣",
            "products_company_discount": "企业采购",
            "products_quotation_for_wholesale": "批发报价",
            "products_request_2nd_hand": "出售二手",
            "products_ask_2nd_hand": "征求二手",
            "out_of_stock_subscribe": "返货通知",
            "products_price_change_notifications": "价格变动通知",
            "products_number_players_0": "不适用",
            "products_number_players_1": "1",
            "products_number_players_2": "1-2",
            "products_number_players_3": "1-3",
            "products_number_players_4": "1-4",
            "products_number_players_5": "1-5",
            "products_number_players_6": "1-6",
            "products_number_players_7": "1-8",
            "products_number_players_10": "10+",
            "sales_time_countdown_format": "d 天 h小时 mm分 ss秒",
            "sales_time_left": "尚余",
            "sales_quota_left": "尚余",
            "sales_expired": "优惠已结束",
            "upload_bankslip": "提交付款证明",
            "submitting": "提交中，请稍后...",
            "product_ranking_more": "显示更多排名",
            "general_product_area_empty": "暂未有商品提供",
            "history_empty": "暂未有您的购物记录",
            "view_file": "查看PDF",
            "products_stock_all": "所有",
            "products_stock_1": "足够",
            "products_stock_2": "少量",
            "products_stock_3": "补货中",
            "products_stock_4": "最后一件",
            "products_stock_5": "已售罄",
            "products_stock_label_to_client_1": "约24小时可出货",
            "products_stock_label_to_client_2": "约1-3天内可出货",
            "products_stock_label_to_client_3": "补货中",
            "products_stock_label_to_client_4": "约3-7天内可出货",
            "products_stock_label_to_client_5": "补货中",
            "products_stock_label_to_client_6": "约1星期內可出貨",
            "products_stock_label_to_client_7": "约2-3星期内可出货",
            "products_stock_label_to_client_8": "约1个月內可出货",
            "check_order_statement": "请检查订单是否正确，然后上传付款纪录 / 水单",
            "cart_postal_code_not_found": "请输入正确邮政编号",
            "cart_dhl_error": "DHL API错误",
            "cart_aramex_error": "Aramex API错误",
            "cart_dhl_insurance": "保险投保 (DHL)",
            "cart_error_contact_support": "抱歉，我们暂时未能处理您的订单，请稍后再试或联络客服",
            "cart_contact_support": "很抱歉，由于货品总重量超出可计算范围，请调整后重试。",
            "cart_complete_order_can_earn": "完成此订单可获",
            "cart_grade_need": "尚余",
            "cart_grade_need_message": "消费，便可升级为 ({grade_name})",
            "cart_member": "会员",
            "cart_product": "货品",
            "cart_quantity": "数量",
            "cart_price": "价格",
            "cart_total": "合计",
            "cart_added_value_to_redeem": "加{value_and_dollar_sign}换购",
            "cart_remove_item": "删除",
            "cart_move_to_fav": "稍后购买",
            "cart_estimated_ship_date": "预计出货日期： 约",
            "cart_extracharges": "自选增值服务",
            "cart_extracharges_optional": "",
            "cart_point_redemption_discount": "扣减积分",
            "cart_coupon_code": "电子优惠券",
            "cart_validate_coupon_code": "验证",
            "cart_shipment_information": "送货资料",
            "cart_shipment_method": "送货方式",
            "cart_shipment_country_area": "国家/ 地区",
            "cart_recipient_information": "收件人资料",
            "cart_recipient_name": "姓名",
            "cart_recipient_email": "电邮",
            "cart_recipient_tel": "行动电话：",
            "cart_delivery_address": "送货地址",
            "cart_delivery_address_2": "送货地址（第二行）",
            "cart_delivery_address_3": "送货地址（第三行）",
            "cart_city": "城市",
            "cart_state": "州/省",
            "cart_postal_code": "城市邮政编号",
            "cart_payment_method": "支付方式",
            "cart_consolidation": "是否待货物到齐后一并出货",
            "cart_remarks": "备注",
            "cart_sub_total": "小计：",
            "cart_shipment_fee": "运费：",
            "cart_paypal_extra_charge": "支付方式手续费：",
            "cart_payment_extra_charge": "支付方式手续费：",
            "cart_oversea_extra_charge": "海外倉運費：",
            "cart_zeek_locker": "智能柜编号:",
            "cart_grand_total": "总金额：",
            "cart_redeem_point": "兑换积分：",
            "cart_redeem_coupon": "使用优惠劵: {name}",
            "cart_discount": "优惠",
            "cart_estimated_shipment_date_same": "预计送货日期： {date}",
            "cart_estimated_shipment_date_diff": "预计送货日期： {date_from} 至 {date_to}",
            "cart_estimated_batch_shipment_date": "预计送货日期： ",
            "cart_estimated_batch_shipment_date_diff": "预计第{lot}批送货日期： {date_from} 至 {date_to}",
            "cart_estimated_batch_shipment_date_same": "预计第{lot}批送货日期： {date}",
            "estimate_delivery_caution": "因有预购物品，送货日期会比预计稍迟",
            "cart_confirm_order_and_address": "我已确认订单及核对地址正确",
            "cart_declared_value_for_custom": "报关价值",
            "cart_suggested_declared_value_for_custom": "建议报关价值",
            "cart_continue_shopping": "继续选购",
            "cart_checkout_confirm": "确认结账",
            "cart_empty": "您尚未购买任何东西，请按此进行购物。",
            "cart_updated": "购物车已更新",
            "cart_please_pick": "请选择",
            "remove_discount": "移除优惠",
            "cart_district": "分区",
            "cart_area": "区域",
            "cart_delivery_store_code": "点码",
            "cart_delivery_store_code_enter": "请输入顺丰点码",
            "cart_delivery_store_code_post": "邮政局",
            "cart_delivery_store_code_ipost": "智邮站",
            "cart_delivery_store_code_post_enter": "请输入邮局名称/智邮站点",
            "cart_calculate_shipment_title": "邮费计算中",
            "cart_calculate_shipment_content": "正在计算邮费中",
            "cart_delivery_city_is_null": "城市必须填写",
            "cart_declared_value_for_custom_is_null": "报关价值必须填写",
            "cart_delivery_postal_code_is_null": "邮政编码必须填写",
            "cart_remove_out_of_stock": "因此货件暂时缺货，我们现先为您移除此货件",
            "delivery_method_is_null": "送货方式必须选择",
            "address_is_null": "地址必须填写",
            "name_is_null": "姓名必须填写",
            "tel_is_null": "手提电话必须填写",
            "email_is_null": "电邮必须填写",
            "delivery_store_code_is_null": "点码必须填写",
            "payment_method_is_null": "支付方式必须选择",
            "confirm_order_and_address_must_tick": "必须勾选确认订单及核对地址正确",
            "zeeK_locker_code": "<p>供应商: {type}<br>名称: {name}<br>编号: {code}<br>地址： {address}<br>开放时间： {opening_hour}</p>",
            "fav_product": "货品",
            "fav_quantity": "数量",
            "fav_price": "价格",
            "fav_total": "合计",
            "fav_remove_item": "移除",
            "fav_move_to_cart": "加入购物車",
            "fav_estimated_ship_date": "预计出货日期：",
            "fav_empty": "您尚未于喜爱清单加入任何东西，请按此浏览各款热卖商品及优惠。",
            "fav_updated": "喜爱清单已更新",
            "order_history_empty": "尚未有任何订单纪录，请按此选购各款热卖商品及优惠。。",
            "order_not_found": "找不到相关订单，请按此开始选购各款热卖商品及优惠。",
            "order_not_confirmed": "订单仍在处理中, 请稍候我们发出之确认订单电邮。",
            "order_paid": "付款証明已成功提交。",
            "file_size_limit": "你上载的档案过大，请再尝试上载5M或以下的档案",
            "file_size_limit_text": "上载档案限制：5MB内",
            "order_no": "订单编号",
            "order_offline_payment": "付款证明",
            "order_upload_offline_payment": "上传付款证明",
            "order_offline_payment_upload_time": "上传时间",
            "order_offline_payment_upload_file": "上传文件",
            "order_offline_payment_file": "档案",
            "order_offline_payment_remarks": "备注",
            "order_change": "更改订单",
            "order_cancel": "取消订单",
            "order_view": "查看订单",
            "order_change_request": "修改订单纪录",
            "order_submit_change": "修改 / 取消订单",
            "order_change_request_type": "订单修改类别",
            "order_change_request_request": "客户要求描述",
            "order_change_request_reply": "客户服务回复",
            "order_change_request_status": "状态",
            "order_change_request_type_1": "修改订单",
            "order_change_request_type_2": "取消订单",
            "order_change_request_status_1": "处理中",
            "order_change_request_status_2": "接受",
            "order_change_request_status_3": "拒绝",
            "order_comment": "评分& 撰写评价",
            "order_comment_comment": "评价",
            "order_comment_reply": "客户服务回复",
            "order_comment_status": "状态",
            "order_comment_status_1": "已提交",
            "order_comment_status_2": "已查阅",
            "payment_not_net_settle_comment": "一直未收到客户之付款通知",
            "invoice": "发票",
            "invoice_name": "姓名",
            "invoice_email": "联络电邮",
            "invoice_tel": "行动电话：",
            "invoice_payment_method": "付款方法",
            "invoice_delievey_method": "运送方式",
            "invoice_delievey_address": "送货地址",
            "invoice_postal_code": "邮政编码",
            "invoice_remark": "备注",
            "invoice_remark_buygame2": "店方备注",
            "invoice_declared_value_for_custom": "報關價值",
            "invoice_country": "国家",
            "invoice_state": "州/省",
            "invoice_city": "城市",
            "invoice_product_name": "产品名称",
            "invoice_category": "分类",
            "invoice_version": "版本",
            "invoice_unit_price": "单价",
            "invoice_quantity": "数量",
            "invoice_subtotal": "小计",
            "invoice_delievery_charge": "运费",
            "invoice_service_charge": "支付方式手续费",
            "invoice_extra_charge": "自选增值服务",
            "invoice_discount": "现金回赠",
            "invoice_grand_total": "合计",
            "invoice_used_point": "已使用积分",
            "point": "分",
            "point_full": "积分",
            "message_page_expired": "无此页面！请返回首页。",
            "all_right_reserved_term": "Buy Game 2.com. All rights reserved.",
            "network_error": "网络错误",
            "please_retry_later": "请稍后再试",
            "order_no_records": "没有记录",
            "share": "分享产品给朋友",
            "group_buy": "团购折扣",
            "company_buy": "企业采购",
            "wholesale": "批发报价",
            "2nd_hand_buy": "征求二手",
            "2nd_hand_sell": "出售二手",
            "price_change": "价格变动通知",
            "waiting_restock": "缺货登记",
            "products_2nd_hand": "二手",
            "products_pre_order": "预约",
            "products_restock": "补货",
            "battery_included": "此产品包含电池",
            "update_cart": "由于你已更新购物车，请重新选择送货方式",
            "contact_info": "联络资料",
            "contact_address": "地址：",
            "email_normal_enquiry": "电邮（一般查询）：",
            "whatsapp": "Whatsapp（一般查询）：",
            "email_collaborate": "电邮（洽谈合作）：",
            "skype": "Skype：",
            "facebook": "Facebook：",
            "instagram": "Instagram:",
            "twitter": "Twitter：",
            "weibo": "微博：",
            "internal_remark": "店方备注：",
            "reason": "原因: {reason}",
            "enquiry_type": "查询主题：",
            "enquiry_type_1": "货件有问题",
            "enquiry_type_2": "企业采购",
            "enquiry_type_3": "二手征求",
            "enquiry_type_4": "免费代销",
            "enquiry_type_5": "一般查询",
            "enquiry_type_6": "团购折扣",
            "enquiry_type_7": "批发报价",
            "enquiry_type_8": "更改/ 取消订单",
            "enquiry_type_9": "邮件追查",
            "enquiry_type_10": "付款通知",
            "enquiry_type_11": "投诉或建议",
            "enquiry_type_12": "开始预订通知",
            "enquiry_type_13": "缺货登记",
            "enquiry_type_14": "企业采购",
            "enquiry_urgent": "紧急查询：",
            "enquiry_urgent_yes": "是",
            "enquiry_name": "联络人：",
            "enquiry_email": "电邮：",
            "enquiry_tel": "行动电话：",
            "enquiry_country": "国家：",
            "enquiry_country_select": "请选择",
            "enquiry_title": "查询主题：",
            "enquiry_title_select": "请选择主题",
            "enquiry_1_order_no": "订单号码：",
            "enquiry_1_rma_reason": "货件问题：",
            "enquiry_2_product_name_or_no": "货件名称/ 货件编号：",
            "enquiry_2_product_name_or_no$1": "货件名称/ 货件编号：",
            "enquiry_2_product_name_or_no$2": "货件名称/ 货件编号：",
            "enquiry_2_product_name_or_no$3": "货件名称/ 货件编号：",
            "enquiry_2_product_name_or_no$4": "货件名称/ 货件编号：",
            "enquiry_2_product_name_or_no$5": "货件名称/ 货件编号：",
            "enquiry_2_supply_quantity$1": "数量：",
            "enquiry_2_supply_quantity$2": "数量：",
            "enquiry_2_supply_quantity$3": "数量：",
            "enquiry_2_supply_quantity$4": "数量：",
            "enquiry_2_supply_quantity$5": "数量：",
            "enquiry_2_company_name": "公司名称：",
            "enquiry_3_product_name_or_no$1": "货件名称/ 货件编号：",
            "enquiry_3_budget$1": "预算价格：",
            "enquiry_3_need_dlc$1": "是否需要DLC：",
            "enquiry_3_product_name_or_no$2": "货件名称/ 货件编号：",
            "enquiry_3_budget$2": "预算价格：",
            "enquiry_3_need_dlc$2": "是否需要DLC：",
            "enquiry_3_product_name_or_no$3": "货件名称/ 货件编号：",
            "enquiry_3_budget$3": "预算价格：",
            "enquiry_3_need_dlc$3": "是否需要DLC：",
            "enquiry_3_product_name_or_no$4": "货件名称/ 货件编号：",
            "enquiry_3_budget$4": "预算价格：",
            "enquiry_3_need_dlc$4": "是否需要DLC：",
            "enquiry_3_product_name_or_no$5": "货件名称/ 货件编号：",
            "enquiry_3_budget$5": "预算价格：",
            "enquiry_3_need_dlc$5": "是否需要DLC：",
            "enquiry_3_acceptable_prepare_time$1": "可等候补货时间：",
            "enquiry_3_acceptable_prepare_time$2": "可等候补货时间：",
            "enquiry_3_acceptable_prepare_time$3": "可等候补货时间：",
            "enquiry_3_acceptable_prepare_time$4": "可等候补货时间：",
            "enquiry_3_acceptable_prepare_time$5": "可等候补货时间：",
            "enquiry_4_product_name_or_no$1": "货件名称/ 货件编号：",
            "enquiry_4_supply_price$1": "出售价格：",
            "enquiry_4_supply_quantity$1": "数量：",
            "enquiry_4_product_name_or_no$2": "货件名称/ 货件编号：",
            "enquiry_4_supply_price$2": "出售价格：",
            "enquiry_4_supply_quantity$2": "数量：",
            "enquiry_4_product_name_or_no$3": "货件名称/ 货件编号：",
            "enquiry_4_supply_price$3": "出售价格：",
            "enquiry_4_supply_quantity$3": "数量：",
            "enquiry_4_product_name_or_no$4": "货件名称/ 货件编号：",
            "enquiry_4_supply_price$4": "出售价格：",
            "enquiry_4_supply_quantity$4": "数量：",
            "enquiry_4_product_name_or_no$5": "货件名称/ 货件编号：",
            "enquiry_4_supply_price$5": "出售价格：",
            "enquiry_4_supply_quantity$5": "数量：",
            "enquiry_5_normal_content": "內容：",
            "enquiry_6_product_name_or_no": "货件名称/ 货件编号：",
            "enquiry_6_budget": "预算价格：",
            "enquiry_6_quantity": "数量：",
            "enquiry_7_product_name_or_no": "货件名称/ 货件编号：",
            "enquiry_7_product_name_or_no$1": "货件名称/ 货件编号：",
            "enquiry_7_product_name_or_no$2": "货件名称/ 货件编号：",
            "enquiry_7_product_name_or_no$3": "货件名称/ 货件编号：",
            "enquiry_7_product_name_or_no$4": "货件名称/ 货件编号：",
            "enquiry_7_product_name_or_no$5": "货件名称/ 货件编号：",
            "enquiry_7_supply_quantity": "数量：",
            "enquiry_7_supply_quantity$1": "数量：",
            "enquiry_7_supply_quantity$2": "数量：",
            "enquiry_7_supply_quantity$3": "数量：",
            "enquiry_7_supply_quantity$4": "数量：",
            "enquiry_7_supply_quantity$5": "数量：",
            "enquiry_7_country_of_product_origin": "貨品所在國家：",
            "enquiry_7_company_name": "公司名称：",
            "enquiry_8_order_no": "订单号码：",
            "enquiry_8_order_change_reason": "更改或取消要求详情：",
            "enquiry_9_order_no": "订单号码：",
            "enquiry_9_delivery_method": "邮寄方式：",
            "enquiry_10_offline_payment_receipt": "付款证明：",
            "enquiry_10_offline_payment_bank": "入账银行：",
            "enquiry_10_offline_payment_time": "入账时间：",
            "enquiry_10_offline_payment_amount": "入账银码：",
            "enquiry_11_content": "备注：",
            "enquiry_12_product_name_or_no": "货件名称/ 货件编号：",
            "enquiry_13_product_name_or_no": "货件名称/ 货件编号：",
            "enquiry_14_company_name": "企业名称：",
            "enquiry_14_company_contact": "联络电邮：",
            "enquiry_14_purchase_content": "采购事项：",
            "enquiry_other": "其他：",
            "enquiry_submit": "送出查询",
            "enquiry_submit_title": "查询表格",
            "enquiry_submit_message": "您已成功提交查询，我们会尽快回复。",
            "share_friend": "分享",
            "share_friend_email": "朋友 {count} 电邮地址：",
            "share_submit_message": "您已成功把此商品分享给朋友",
            "share_message_title": "您的朋友向您推介此商品",
            "share_message_part1": "亲, 您好!",
            "share_message_part2": "您的朋友 {enquiry_name} 向您推介 BuyGame2 的珍贵商品 {product_name_link}。",
            "share_message_part3": "请浏览 {product_link} 进一步查看详情！",
            "share_message_part4": "BuyGame2.com",
            "out_of_stock_subscribe_success": "我们已为你登记好了，当此商品再补货后，我们会立即电邮给你，请留意你的电子邮箱。",
            "out_of_stock_subscribe_success2": "温馨提示: 请记得把我们的电邮加入你的安全名单，以免我们的电邮被误跌入杂件箱，以错过我们的通知啊。",
            "bg2_system": "网站管理平台",
            "bg2_dot_com": "Buy Game 2.com"
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "passwords": {
            "password": "Passwords must be at least six characters and match the confirmation.",
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "offline_payment_receipt": "Payment proof"
            }
        }
    },
    "zh-hk": {
        "auth": {
            "failed": "用戶名稱或密碼不正確",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "frontend": {
            "stock_enough": "24小時",
            "stock_less": "1-3天",
            "restock_soon": "補貨中",
            "stock_one_left": "3-7天",
            "stock_out_of_stock": "補貨中",
            "stock_one_week": "1星期",
            "stock_two_week": "2-3星期",
            "stock_one_month": "1個月",
            "product_description": "產品描述",
            "keyword": "關鍵字",
            "register": "註冊",
            "login": "登入",
            "register_and_login": "註冊/登入",
            "logout": "登出",
            "favourite_list": "喜愛清單",
            "account": "帳戶",
            "cart": "購物車",
            "home": "主頁",
            "back": "返回",
            "testimonial": "傳媒報導",
            "news": "最新消息",
            "shop_addr": "門市資料",
            "blog": "BLOG",
            "success": "成功",
            "coupon_not_found": "找不到優惠劵",
            "apply_success": "成功使用優惠劵",
            "out_of_limit": "優惠劵已超出可用數量",
            "remove_success": "成功移除優惠劵",
            "games": "電玩",
            "household_goods": "生活百貨",
            "digital_goods": "數碼影音",
            "boutiques": "玩具精品",
            "mobile_submit_bank_receipt": "提交入帳記錄",
            "submit_bank_receipt": "已提交入帳記錄",
            "check_order": "查看訂單狀態",
            "check_order_input_order_no": "輸入訂單編號",
            "check_order_submit": "提交",
            "whatsapp_group_invite_text_short": "接收神秘優惠",
            "whatsapp_group_invite_text": "接收神秘What apps優惠",
            "whatsapp_group_input": "輸入手提電話",
            "whatsapp_group_confirm": "接收",
            "whatsapp_group_remark": "請記得把98262852也加入您的通訊錄，才能成功接收到優惠",
            "whatsapp_chat_text": "我有興趣買您的貨品",
            "no_state": "沒有州/省",
            "wechat_invite_text": "官方微信帳號：",
            "register_username": "用戶名稱",
            "register_surname": "姓",
            "register_givenname": "名",
            "register_gender": "性別",
            "register_email": "電郵",
            "register_phone": "手提電話",
            "register_age_group": "年齡組別",
            "register_age_group_0": "待更新",
            "register_age_group_1": "20 歲以下",
            "register_age_group_2": "20 歲 - 29 歲",
            "register_age_group_3": "30 歲 - 39 歲",
            "register_age_group_4": "40 歲或以上",
            "register_address_1": "地址1",
            "register_address_2": "地址2",
            "register_address_3": "地址3",
            "register_address_4": "地址4",
            "register_zip_code": "郵政編號",
            "register_country": "國家",
            "register_income_group": "入息範圍",
            "register_income_group_0": "待更新",
            "register_income_group_1": "$5,000 或以下",
            "register_income_group_2": "$5,001 - $10,000",
            "register_income_group_3": "$10,001 - $15,000",
            "register_income_group_4": "$15,001 - $20,000",
            "register_income_group_5": "$20,001 - $25,000",
            "register_income_group_6": "$25,000 或以上",
            "register_password": "密碼（最少6個字）",
            "register_password_confirm": "重新輸入密碼",
            "register_newsletter": "我想訂閱著數情報",
            "register_submit": "提交",
            "register_reset": "重設",
            "register_confirmation_email_subject": "買Game2 - 會員申請電郵確認",
            "register_confirmation_email_title": "您好, {username}",
            "member_gender_male": "男",
            "member_gender_female": "女",
            "maintenance_by_bg2": "本公司保養",
            "maintenance": "保養",
            "member_login": "會員登入",
            "non_member": "訪客購物通道",
            "continue_buy": "繼續購買",
            "non_member_buy": "訪客單次直購",
            "member_buy": "會員購物通道",
            "member_jetso": "成為會員可賺取積分",
            "instant_register": "立即註冊成為會員",
            "login_criteria": "電郵 / 用戶名稱 / 電話",
            "member_email": "電郵",
            "member_username": "用戶名稱",
            "member_tel": "手提電話",
            "or": "或",
            "member_password": "密碼",
            "member_forgot_password": "忘記密碼?",
            "member_no_account": "未成為會員? ",
            "member_register_immediately": "即時註冊!",
            "member_email_verification": "會員電郵驗證",
            "member_email_verify_message": "您已成功註冊！請驗證您的電郵。",
            "member_email_verified_message": "會員電郵驗證完成！你已可以進入登入。",
            "member_reset_password": "忘記密碼",
            "member_submit_reset_password": "重設密碼",
            "member_submit_reset_password_message": "已收到你重設密碼的要求！請檢查您的電郵。",
            "member_new_password": "新密碼",
            "member_submit_reset_password_finished_message": "已完成重設密碼！你可再次嘗試登入。",
            "member_level": "會員級別",
            "member_page": "會員中心",
            "member_policy": "會員制度",
            "member_greeting": "你好！",
            "order_info": "訂單資訊",
            "member_order_history": "購買記錄",
            "member_points": "積分記錄",
            "member_points_select_year": "請選擇年份",
            "member_no_records": "沒有相關記錄",
            "member_point_balance": "積分結餘",
            "member_point_expire": "積分到期",
            "member_point_expire_message": "有{point}分將於{date}到期",
            "member_account_information": "個人資料",
            "member_favourite_list": "喜愛清單",
            "member_coupons": "電子優惠券",
            "member_order_number": "訂單編號",
            "member_purchase_date": "訂購日期",
            "member_delivery_way": "送貨方式",
            "member_purchase_status": "訂購狀態",
            "member_delivery_progress": "貨運完成度",
            "member_delivery_track": "追蹤運送情況",
            "member_track": "追蹤",
            "member_print_order": "列印訂單",
            "member_point_date": "日期",
            "member_point_remarks": "備註",
            "member_status_shopping_point": "現有積分：",
            "member_status_like_count": "被讚好次數：",
            "member_point_type_1": "完成訂單",
            "member_point_type_2": "讚好他人留言",
            "member_point_type_3": "對產品評價及留言",
            "member_point_type_4": "被他人讚好",
            "member_point_type_5": "使用積分",
            "member_point_type_6": "由管理員的積分變更",
            "member_point_type_7": "退貨",
            "member_point_type_998": "首次購物",
            "member_point_type_999": "登記會員",
            "about_us": "關於我們",
            "how_to_purchase": "如何購買",
            "contact_us": "聯絡我們",
            "support": "支援服務",
            "terms": "條款及細則",
            "how_to_buy": "如何購買",
            "media": "傳媒報導",
            "Jan": "一月",
            "Feb": "二月",
            "Mar": "三月",
            "Apr": "四月",
            "May": "五月",
            "Jun": "六月",
            "Jul": "七月",
            "Aug": "八月",
            "Sep": "九月",
            "Oct": "十月",
            "Nov": "十一月",
            "Dec": "十二月",
            "enter_keyword": "輸入關鍵字",
            "issue_date": "發佈日期",
            "all": "全部",
            "read_more": "查看更多",
            "reserve_product": "預約貨品",
            "order_status": "訂購狀態",
            "order_status_desc": "說明",
            "order_status_1": "已完成",
            "order_status_2": "處理中",
            "order_status_3": "付款提醒",
            "order_status_4": "已取消",
            "order_status_5": "已確認",
            "order_status_6": "已付款/已落訂",
            "order_status_7": "廷長處理",
            "order_status_8": "可供提貨",
            "order_status_9": "待付款",
            "order_status_10": "取貨提醒",
            "order_status_1_desc": "訂單已被提取或寄出，購物積分已加至會員戶口",
            "order_status_2_desc": "訂單一般在2個工作天內完成處理，現階段無需付款",
            "order_status_3_desc": "已落實之訂單仍等待客戶付款中，若3天內仍未收到付款通知，訂單將會自動被取消",
            "order_status_4_desc": "訂單因未付款/ 缺貨/ 客戶要求而已被取消",
            "order_status_5_desc": "訂單正等待客戶付款或上載付款証明",
            "order_status_6_desc": "信用咭已過數 或 已收到客戶上載之付款証明",
            "order_status_7_desc": "由於訂單中的部分貨件仍在努力安排中，故未能落實訂單",
            "order_status_8_desc": "訂單正等待客戶取貨，貨件保留14天後會被棄置",
            "order_status_9_desc": "仍未收到客戶上載之付款証明 或 信用咭無法過數",
            "order_status_10_desc": "貨件已超過可提貨期限，若3天內仍未取貨，將會被棄置，款項亦不能退回",
            "whatsapp_notice": "買Game2通知: 您的訂單({ref_no})已到貨，請於14天內到深水埗長沙灣道266-268號，昌發商業大廈 402-404室 BuyGame2門市取貨，逾期無效",
            "whatsapp_notice_mtr": "買Game2通知: 您的訂單({ref_no})已到貨，請回覆我們相約在14天內，於深水埗地鐵站C/ D出閘口交收，逾期無效",
            "all_products": "全部貨品",
            "wednesday_crazy_sales_area": "星期三限量秒殺區",
            "preorder_rank": "預訂排行榜",
            "weekly_top_50": "每週Top 50",
            "youtube": "Youtube頻道",
            "limited_time_sales_area": "限時優惠區",
            "popular_consoles": "熱門遊戲主機",
            "hot_items_area": "熱賣區",
            "new_arrivals_area": "最新上架區",
            "restocked_area": "再補貨區",
            "recommendation_area": "熱門推介區",
            "preorder_area": "最新預約區",
            "sales_area": "最新減價區",
            "clearance_area": "平價特賣場",
            "second_hands_area": "最新二手貨區",
            "second_hands": "二手貨品",
            "other_ppls_choice": "其他用戶也購買",
            "your_favourites": "猜你喜歡",
            "browse_history": "最近瀏覽過的商品",
            "related_products": "相關產品",
            "checkout_now": "立即購買",
            "product_category": "產品分類",
            "shop_info": "商店資訊",
            "settings": "設定",
            "input": "輸入",
            "search": "搜尋",
            "search_reset": "重設",
            "search_placeholder_gamename": "遊戲名稱",
            "select_product_type": "選擇分類",
            "product_search_input": "輸入",
            "site_wide_search": "全站搜尋",
            "advanced_search": "進階搜尋",
            "hot_search": "熱門搜尋",
            "search_result": "搜尋結果",
            "more_result": "更多結果",
            "search_additional_rules": "其它條件",
            "search_sorting_criteria": "商品排序",
            "search_arrival_date_placeholder": "日月年 - 日月年",
            "search_input_placeholder": "請在此輸入...",
            "search_criteria_newest_sales": "最新減價",
            "search_criteria_buy_from_shop": "門市直購",
            "search_criteria_sales_price": "優惠價格",
            "search_criteria_sort_A-Z": "順序 A-Z",
            "search_criteria_sort_Z-A": "倒序 Z-A",
            "search_criteria_create_date": "上架時間",
            "search_criteria_product_price": "價格高低",
            "showing_console_type": "顯示機種：{type}",
            "product_all": "所有",
            "product_type_all": "所有",
            "product_type_more": "更多產品",
            "product_search_results": "搜尋結果",
            "product_search_results_unit": "個",
            "product_show": "顯示",
            "product_search_default": "預設",
            "products_no": "產品編號",
            "products_name": "名稱",
            "products_console": "機種",
            "products_type": "類型",
            "products_genre": "遊戲種類",
            "products_version": "版本",
            "products_language": "語言",
            "products_dialogue_language": "字幕語言",
            "products_audio_language": "語音語言",
            "products_stock_label": "貨件狀態",
            "products_players_count": "遊玩人數",
            "products_players_online": "在線",
            "products_players_offline": "離線",
            "products_number_player_online": "在線人數",
            "products_number_player_offline": "離線人數",
            "products_origin": "來源地",
            "products_video_compatibility": "兼容性",
            "products_no_protection": "沒有區碼保護",
            "products_reward_member_point": "購物積分",
            "products_arrival_date": "推出日期",
            "products_product_stock_status": "庫存狀況",
            "products_refer_link": "參考網址",
            "products_origin_name": "原名",
            "products_model_no": "型號",
            "products_youtube": "影片",
            "products_click_here": "請按此",
            "products_pre_sales_price": "原價",
            "products_sales_price": "現價",
            "products_price": "價格",
            "products_point": "積分",
            "products_discount_saving": "節省",
            "products_details": "詳細資料",
            "products_redemption_discounts": "換購優惠",
            "products_add_value_to_redeem": "加{value_and_dollar_sign}可換購",
            "products_add_to_cart": "加入購物車",
            "products_add_to_favourite": "加入喜愛清單",
            "products_share": "分享",
            "products_buy_at_store": "*接受門市直購",
            "oversea_warehouse": "*美國倉直接發貨",
            "products_oversea": "*支援由我們的美國倉直接發貨",
            "products_digital_goods_only": "*經電郵傳送",
            "products_sell_online_only": "*只接受網上購買",
            "products_allow_pre_order": "接受預訂中",
            "products_customer_review": "客戶評價",
            "prodcuts_customer_review_success": "你已成功留言。",
            "products_customer_review_like_success": "你已成功對客戶留言讚好。",
            "products_customer_review_like_failed": "網絡錯誤，請稍後再試。",
            "products_customer_review_like_duplicate": "你已對此產品作出評價。",
            "products_rating_avg": "平均分",
            "products_create_review": "我要評分/留言",
            "products_message_placeholder": "請輸入內容…",
            "products_rating_gameplay": "好玩程度：",
            "products_rating_graphics": "畫面：",
            "products_rating_sound": "音效：",
            "products_rating_lasting_appeal": "推介程度：",
            "please_enter": "請輸入",
            "validate": "作驗證",
            "currency": "貨幣",
            "products_group_discount": "團購折扣",
            "products_company_discount": "企業採購",
            "products_quotation_for_wholesale": "批發報價",
            "products_request_2nd_hand": "出售二手",
            "products_ask_2nd_hand": "徵求二手",
            "out_of_stock_subscribe": "返貨通知",
            "products_price_change_notifications": "價格變動通知",
            "products_number_players_0": "不適用",
            "products_number_players_1": "1",
            "products_number_players_2": "1-2",
            "products_number_players_3": "1-3",
            "products_number_players_4": "1-4",
            "products_number_players_5": "1-5",
            "products_number_players_6": "1-6",
            "products_number_players_7": "1-8",
            "products_number_players_10": "10+",
            "sales_time_countdown_format": "d 天 h小時 mm分 ss秒",
            "sales_time_left": "尚餘",
            "sales_quota_left": "尚餘",
            "sales_expired": "優惠已結束",
            "upload_bankslip": "提交付款証明",
            "submitting": "提交中, 請稍後...",
            "product_ranking_more": "顯示更多排名",
            "general_product_area_empty": "暫未有商品顯示，請多留意Buygame2.com以取得最新貨品及優惠資訊。",
            "history_empty": "暫未有你的歷史記錄。",
            "products_stock_all": "所有",
            "products_stock_1": "足夠",
            "products_stock_2": "少量",
            "products_stock_3": "補貨中",
            "products_stock_4": "最後一件",
            "products_stock_5": "已售罄",
            "products_stock_label_to_client_1": "約24小時可出貨",
            "products_stock_label_to_client_2": "約1-3天內可出貨",
            "products_stock_label_to_client_3": "補貨中",
            "products_stock_label_to_client_4": "約3-7天內可出貨",
            "products_stock_label_to_client_5": "補貨中",
            "products_stock_label_to_client_6": "約1星期內可出貨",
            "products_stock_label_to_client_7": "約2-3星期內可出貨",
            "products_stock_label_to_client_8": "約1個月內可出貨",
            "check_order_statement": "請檢查訂單是否正確，然後於上傳入數紀錄。",
            "cart_postal_code_not_found": "請輸入正確郵政編號",
            "cart_dhl_error": "DHL API錯誤",
            "cart_aramex_error": "Aramex API錯誤",
            "cart_dhl_insurance": "保險投保 (DHL)",
            "cart_contact_support": "很抱歉，由於貨品總重量超出可計算範圍，請調整後重試。",
            "cart_error_contact_support": "抱歉，我們暫時未能處理您的訂單，請稍後再試或聯絡客服",
            "cart_complete_order_can_earn": "完成此訂單可獲",
            "cart_grade_need": "尚餘",
            "cart_grade_need_message": "消費，便可升級為 ({grade_name}) ",
            "cart_member": "會員",
            "cart_product": "貨品",
            "cart_quantity": "數量",
            "cart_price": "價格",
            "cart_total": "合計",
            "cart_added_value_to_redeem": "加{value_and_dollar_sign}換購",
            "cart_remove_item": "移除",
            "cart_move_to_fav": "稍後購買",
            "cart_estimated_ship_date": "預計出貨日期： 約",
            "cart_extracharges": "自選增值服務",
            "cart_extracharges_optional": "",
            "cart_point_redemption_discount": "積分優惠",
            "cart_coupon_code": "電子優惠券",
            "cart_validate_coupon_code": "驗證",
            "cart_shipment_information": "送貨資料",
            "cart_shipment_method": "送貨方式",
            "cart_shipment_country_area": "國家/地區",
            "cart_recipient_information": "收件人資料",
            "cart_recipient_name": "姓名",
            "cart_recipient_email": "電郵",
            "cart_recipient_tel": "手提電話",
            "cart_delivery_address": "送貨地址（第一行）",
            "cart_delivery_address_2": "送貨地址（第二行）",
            "cart_delivery_address_3": "送貨地址（第三行）",
            "cart_city": "城市",
            "cart_state": "州/省",
            "cart_postal_code": "郵政編號",
            "cart_payment_method": "支付方式",
            "cart_consolidation": "是否待貨物到齊後一併出貨",
            "cart_remarks": "備註",
            "cart_sub_total": "小計：",
            "cart_shipment_fee": "運費：",
            "cart_paypal_extra_charge": "支付服務手續費：",
            "cart_payment_extra_charge": "支付服務手續費：",
            "cart_oversea_extra_charge": "海外倉運費：",
            "cart_zeek_locker": "智能櫃編號:",
            "cart_grand_total": "總金額：",
            "cart_redeem_point": "積分兌換：",
            "cart_redeem_coupon": "使用優惠劵: {name}  ",
            "cart_discount": "優惠",
            "cart_estimated_batch_shipment_date": "預計送貨日期： ",
            "cart_estimated_shipment_date_same": "預計送貨日期： {date}",
            "cart_estimated_shipment_date_diff": "預計送貨日期： {date_from} 至 {date_to}",
            "cart_estimated_batch_shipment_date_not_available": "預計送貨日期：不適用",
            "cart_estimated_batch_shipment_date_diff": "預計第{lot}批送貨日期： {date_from} 至 {date_to}",
            "cart_estimated_batch_shipment_date_same": "預計第{lot}批送貨日期： {date}",
            "estimate_delivery_caution": "因有預購物品，送貨日期會比預計稍遲",
            "cart_confirm_order_and_address": "我已確認訂單及核對地址正確",
            "cart_declared_value_for_custom": "報關價值",
            "cart_suggested_declared_value_for_custom": "建議報關價值",
            "cart_continue_shopping": "繼續選購",
            "cart_checkout_confirm": "確認結帳",
            "cart_empty": "您尚未購買任何東西，請按此進行購物。",
            "cart_updated": "購物車已更新",
            "cart_please_pick": "請選擇",
            "remove_discount": "移除優惠",
            "cart_paypal_quick_checkout_authorized": "您已成功授權付款。接下來，請確認送貨方式及收件者資料。",
            "cart_district": "分區",
            "cart_area": "區域",
            "cart_delivery_store_code": "點碼",
            "cart_delivery_store_code_enter": "請輸入順豐點碼",
            "cart_delivery_store_code_post": "郵政局",
            "cart_delivery_store_code_ipost": "智郵站",
            "cart_delivery_store_code_post_enter": "請輸入郵局名稱/智郵站點",
            "cart_calculate_shipment_title": "郵費計算中",
            "cart_calculate_shipment_content": "正在計算郵費中...",
            "cart_delivery_city_is_null": "城市必須填寫",
            "cart_declared_value_for_custom_is_null": "報關價值必須填寫",
            "cart_delivery_postal_code_is_null": "郵政編碼必須填寫",
            "cart_remove_out_of_stock": "因此貨件暫時缺貨，我們現先為您移除此貨件",
            "address_is_null": "地址必須填寫",
            "name_is_null": "姓名必須填寫",
            "tel_is_null": "手提電話必須填寫",
            "email_is_null": "電郵必須填寫",
            "delivery_method_is_null": "送貨方式必須選擇",
            "delivery_store_code_is_null": "點碼必須填寫",
            "payment_method_is_null": "支付方式必須選擇",
            "confirm_order_and_address_must_tick": "必須勾選確認訂單及核對地址正確",
            "zeeK_locker_code": "<p>供應商: {type}<br>名稱: {name}<br>編號: {code}<br>地址： {address}<br>開放時間： {opening_hour}</p>",
            "fav_product": "貨品",
            "fav_quantity": "數量",
            "fav_price": "價格",
            "fav_total": "合計",
            "fav_remove_item": "移除",
            "fav_move_to_cart": "加入購物車",
            "fav_estimated_ship_date": "預計出貨日期：",
            "fav_empty": "您尚未於喜愛清單添加任何東西，請按此瀏覽Buygame2最新貨品及優惠。",
            "fav_updated": "喜愛清單已更新",
            "order_history_empty": "尚未有任何訂單紀錄，請按此瀏覽Buygame2最新貨品及優惠。",
            "order_not_found": "找不到對應訂單，請按此瀏覽Buygame2最新貨品及優惠。",
            "order_not_confirmed": "訂單仍在處理中, 請稍候我們發出之確認訂單電郵。",
            "order_paid": "付款証明已成功提交。",
            "file_size_limit": "你上載的檔案過大，請再嘗試上載5MB或以下的檔案",
            "file_size_limit_text": "上載檔案限制: 5MB內",
            "order_no": "訂單編號",
            "order_offline_payment": "入數記錄",
            "order_upload_offline_payment": "上傳入數紀錄",
            "order_offline_payment_upload_time": "上傳時間",
            "order_offline_payment_upload_file": "上傳文件",
            "order_offline_payment_file": "文件",
            "order_offline_payment_remarks": "備註",
            "order_change": "要求更改訂單",
            "order_cancel": "要求取消訂單",
            "order_view": "查看訂單",
            "order_change_request": "修改訂單紀錄",
            "order_submit_change": "修改 / 取消訂單",
            "order_change_request_type": "訂單修改類別",
            "order_change_request_request": "要求描述",
            "order_change_request_reply": "客戶服務回覆",
            "order_change_request_status": "狀態",
            "order_change_request_type_1": "修改訂單",
            "order_change_request_type_2": "取消訂單",
            "order_change_request_status_1": "處理中",
            "order_change_request_status_2": "成功",
            "order_change_request_status_3": "失敗",
            "order_comment": "評分 / 撰寫評價",
            "order_comment_comment": "評價",
            "order_comment_reply": "客戶服務回覆",
            "order_comment_status": "狀態",
            "order_comment_status_1": "已提交",
            "order_comment_status_2": "已查閱",
            "payment_not_net_settle_comment": "一直未收到客戶之付款通知",
            "invoice": "發票",
            "invoice_name": "姓名",
            "invoice_email": "聯絡電郵",
            "invoice_tel": "手提電話",
            "invoice_payment_method": "付款方法",
            "invoice_delievey_method": "運送方式",
            "invoice_delievey_address": "送貨地址",
            "invoice_postal_code": "郵政編碼",
            "invoice_remark": "備註",
            "invoice_remark_buygame2": "店方備註",
            "invoice_declared_value_for_custom": "報關價值",
            "invoice_country": "國家",
            "invoice_state": "州/省",
            "invoice_city": "城市",
            "invoice_product_name": "產品名稱",
            "invoice_category": "分類",
            "invoice_version": "版本",
            "invoice_unit_price": "單價",
            "invoice_quantity": "數量",
            "invoice_subtotal": "小計",
            "invoice_delievery_charge": "運費",
            "invoice_service_charge": "支付服務手續費",
            "invoice_extra_charge": "自選增值服務",
            "invoice_discount": "積分優惠 / 其他優惠",
            "invoice_grand_total": "合計",
            "invoice_used_point": "已使用積分",
            "point": "分",
            "point_full": "積分",
            "message_page_expired": "無此頁面！請返回首頁。",
            "all_right_reserved_term": "Buy Game 2.com. All rights reserved.",
            "network_error": "網絡錯誤",
            "please_retry_later": "請稍後再試",
            "view_file": "查看PDF",
            "order_no_records": "沒有記錄。",
            "share": "分享產品給朋友",
            "group_buy": "團購折扣",
            "company_buy": "企業採購",
            "wholesale": "批發報價",
            "2nd_hand_buy": "徵求二手",
            "2nd_hand_sell": "出售二手",
            "price_change": "價格變動通知",
            "waiting_restock": "缺貨登記",
            "products_2nd_hand": "二手",
            "products_pre_order": "預約",
            "products_restock": "補貨",
            "battery_included": "此產品包含電池",
            "update_cart": "由於你已更新購物車，請重新選擇送貨方式",
            "contact_info": "聯絡資料",
            "contact_address": "地址：",
            "email_normal_enquiry": "電郵（一般查詢）：",
            "whatsapp": "Whatsapp（一般查詢）：",
            "email_collaborate": "電郵（洽談合作）：",
            "skype": "Skype：",
            "facebook": "Facebook：",
            "instagram": "Instagram:",
            "twitter": "Twitter：",
            "weibo": "微博：",
            "internal_remark": "店方備註：",
            "reason": "原因： {reason}",
            "enquiry_type": "查詢主題：",
            "enquiry_type_1": "貨件有問題",
            "enquiry_type_2": "企業採購",
            "enquiry_type_3": "二手徵求",
            "enquiry_type_4": "免費代銷",
            "enquiry_type_5": "一般查詢",
            "enquiry_type_6": "團購折扣",
            "enquiry_type_7": "批發報價",
            "enquiry_type_8": "更改或取消訂單",
            "enquiry_type_9": "郵件追查",
            "enquiry_type_10": "付款通知",
            "enquiry_type_11": "投訴或建議",
            "enquiry_type_12": "開始預訂通知",
            "enquiry_type_13": "缺貨登記",
            "enquiry_type_14": "企業採購",
            "enquiry_urgent": "緊急查詢：",
            "enquiry_urgent_yes": "是",
            "enquiry_name": "姓名：",
            "enquiry_email": "電郵：",
            "enquiry_tel": "手提電話：",
            "enquiry_country": "國家：",
            "enquiry_country_select": "請選擇國家",
            "enquiry_title": "查詢主題：",
            "enquiry_title_select": "請選擇主題",
            "enquiry_1_order_no": "訂單號碼：",
            "enquiry_1_rma_reason": "貨件問題：",
            "enquiry_2_product_name_or_no": "貨件名稱/貨件編號：",
            "enquiry_2_product_name_or_no$1": "貨件名稱/貨件編號：",
            "enquiry_2_product_name_or_no$2": "貨件名稱/貨件編號：",
            "enquiry_2_product_name_or_no$3": "貨件名稱/貨件編號：",
            "enquiry_2_product_name_or_no$4": "貨件名稱/貨件編號：",
            "enquiry_2_product_name_or_no$5": "貨件名稱/貨件編號：",
            "enquiry_2_supply_quantity$1": "數量：",
            "enquiry_2_supply_quantity$2": "數量：",
            "enquiry_2_supply_quantity$3": "數量：",
            "enquiry_2_supply_quantity$4": "數量：",
            "enquiry_2_supply_quantity$5": "數量：",
            "enquiry_2_company_name": "公司名稱：",
            "enquiry_3_product_name_or_no$1": "貨件名稱/貨件編號：",
            "enquiry_3_budget$1": "預算價格：",
            "enquiry_3_need_dlc$1": "是否需要DLC：",
            "enquiry_3_product_name_or_no$2": "貨件名稱/貨件編號：",
            "enquiry_3_budget$2": "預算價格：",
            "enquiry_3_need_dlc$2": "是否需要DLC：",
            "enquiry_3_product_name_or_no$3": "貨件名稱/貨件編號：",
            "enquiry_3_budget$3": "預算價格：",
            "enquiry_3_need_dlc$3": "是否需要DLC：",
            "enquiry_3_product_name_or_no$4": "貨件名稱/貨件編號：",
            "enquiry_3_budget$4": "預算價格：",
            "enquiry_3_need_dlc$4": "是否需要DLC：",
            "enquiry_3_product_name_or_no$5": "貨件名稱/貨件編號：",
            "enquiry_3_budget$5": "預算價格：",
            "enquiry_3_need_dlc$5": "是否需要DLC：",
            "enquiry_3_acceptable_prepare_time$1": "可等候補貨時間：",
            "enquiry_3_acceptable_prepare_time$2": "可等候補貨時間：",
            "enquiry_3_acceptable_prepare_time$3": "可等候補貨時間：",
            "enquiry_3_acceptable_prepare_time$4": "可等候補貨時間：",
            "enquiry_3_acceptable_prepare_time$5": "可等候補貨時間：",
            "enquiry_4_product_name_or_no$1": "貨件名稱/貨件編號：",
            "enquiry_4_supply_price$1": "供貨價格：",
            "enquiry_4_supply_quantity$1": "數量：",
            "enquiry_4_product_name_or_no$2": "貨件名稱/貨件編號：",
            "enquiry_4_supply_price$2": "供貨價格：",
            "enquiry_4_supply_quantity$2": "數量：",
            "enquiry_4_product_name_or_no$3": "貨件名稱/貨件編號：",
            "enquiry_4_supply_price$3": "供貨價格：",
            "enquiry_4_supply_quantity$3": "數量：",
            "enquiry_4_product_name_or_no$4": "貨件名稱/貨件編號：",
            "enquiry_4_supply_price$4": "供貨價格：",
            "enquiry_4_supply_quantity$4": "數量：",
            "enquiry_4_product_name_or_no$5": "貨件名稱/貨件編號：",
            "enquiry_4_supply_price$5": "供貨價格：",
            "enquiry_4_supply_quantity$5": "數量：",
            "enquiry_5_normal_content": "內容：",
            "enquiry_6_product_name_or_no": "貨件名稱/貨件編號：",
            "enquiry_6_budget": "預算價格：",
            "enquiry_6_quantity": "數量：",
            "enquiry_7_product_name_or_no": "貨件名稱/貨件編號：",
            "enquiry_7_product_name_or_no$1": "貨件名稱/貨件編號：",
            "enquiry_7_product_name_or_no$2": "貨件名稱/貨件編號：",
            "enquiry_7_product_name_or_no$3": "貨件名稱/貨件編號：",
            "enquiry_7_product_name_or_no$4": "貨件名稱/貨件編號：",
            "enquiry_7_product_name_or_no$5": "貨件名稱/貨件編號：",
            "enquiry_7_supply_quantity": "數量：",
            "enquiry_7_supply_quantity$1": "數量：",
            "enquiry_7_supply_quantity$2": "數量：",
            "enquiry_7_supply_quantity$3": "數量：",
            "enquiry_7_supply_quantity$4": "數量：",
            "enquiry_7_supply_quantity$5": "數量：",
            "enquiry_7_country_of_product_origin": "貨品所在國家：",
            "enquiry_7_company_name": "公司名稱：",
            "enquiry_8_order_no": "訂單號碼：",
            "enquiry_8_order_change_reason": "更改或取消要求詳情：",
            "enquiry_9_order_no": "訂單號碼：",
            "enquiry_9_delivery_method": "郵寄方式：",
            "enquiry_10_offline_payment_receipt": "入數收條：",
            "enquiry_10_offline_payment_bank": "入帳銀行：",
            "enquiry_10_offline_payment_time": "入帳時間：",
            "enquiry_10_offline_payment_amount": "入帳銀碼：",
            "enquiry_11_content": "意見內容：",
            "enquiry_12_product_name_or_no": "貨件名稱/貨件編號：",
            "enquiry_13_product_name_or_no": "貨件名稱/貨件編號：",
            "enquiry_14_company_name": "企業名稱：",
            "enquiry_14_company_contact": "企業聯絡(電郵)：",
            "enquiry_14_purchase_content": "採購事項：",
            "enquiry_other": "其他：",
            "enquiry_submit": "送出查詢",
            "enquiry_submit_title": "查詢表格",
            "enquiry_submit_message": "您已成功提交查詢，我們會盡快回覆。",
            "share_friend": "分享",
            "share_friend_email": "朋友 {count} 電郵地址：",
            "share_submit_message": "您已成功把產品訊息分享給朋友。",
            "share_message_title": "您的朋友向您推介遊戲產品",
            "share_message_part1": "您好！",
            "share_message_part2": "您的朋友 {enquiry_name} 向您推介 BuyGame2 的產品 {product_name_link}。",
            "share_message_part3": "請瀏覽 {product_link} 了解有關產品的資訊！",
            "share_message_part4": "BuyGame2.com",
            "out_of_stock_subscribe_success": "我們已為你登記好了，當此商品再補貨後，我們會立即電郵給你，請留意你的電子郵箱。",
            "out_of_stock_subscribe_success2": "溫馨提示: 請記得把我們的電郵加入你的安全名單，以免我們的電郵被誤跌入雜件箱，以錯過我們的通知啊。",
            "price_change_subscribe_success": "我們已為你登記好了，當此商品有特別優惠時，我們會立即電郵給你，請留意你的電子郵箱。",
            "price_change_subscribe_success2": "溫馨提示: 請記得把我們的電郵加入你的安全名單，以免我們的電郵被誤跌入雜件箱，以錯過我們的通知啊。",
            "bg2_system": "網站管理平台",
            "bg2_dot_com": "Buy Game 2.com"
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "passwords": {
            "password": "Passwords must be at least six characters and match the confirmation.",
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "validation": {
            "accepted": "必須接受 {attribute}",
            "active_url": "{attribute} 不是有效的URL",
            "after": "{attribute} 必須是之後的日期 {date}",
            "after_or_equal": "{attribute} 必須是等於 {date} 或之後的日期",
            "alpha": "{attribute} 只能包含字母",
            "alpha_dash": "{attribute} 只能包含字母，數字和短劃線",
            "alpha_num": "{attribute} 只能包含字母和數字",
            "array": "{attribute} 必須為陣列",
            "before": "{attribute} 必須要早於 {date}",
            "before_or_equal": "{attribute} 必須要等於 {date} 或更早",
            "between": {
                "numeric": "{attribute} 必須介於 {min} 至 {max} 之間",
                "file": "{attribute} 必須介於 {min} 至 {max} KB 之間 ",
                "string": "{attribute} 必須介於 {min} 至 {max} 個字元之間",
                "array": "{attribute}: 必須有 {min} - {max} 個元素"
            },
            "boolean": "{attribute} 必須為布林值",
            "confirmed": "{attribute} 確認欄位的輸入不一致",
            "date": "{attribute} 並非一個有效的日期",
            "date_equals": "{attribute} 必須等於 {date}",
            "date_format": "{attribute} 不符合 {format} 的格式",
            "different": "{attribute} 與 {other} 必須不同",
            "digits": "{attribute} 必須是 {digits} 位數字",
            "digits_between": "{attribute} 必須介於 {min} 至 {max} 位數字",
            "dimensions": "{attribute} 圖片尺寸不正確",
            "distinct": "{attribute} 已經存在",
            "email": "{attribute} 必須是一個有效的E-mail地址",
            "exists": "所選擇的 {attribute} 選項無效",
            "file": "{attribute} 必須是一個檔案",
            "filled": "{attribute} 不能留空",
            "image": "{attribute} 必須是一張圖片",
            "in": "所選擇的 {attribute} 選項無效",
            "in_array": "{attribute} 沒有在 {other} 中",
            "integer": "{attribute} 必須是一個整數",
            "ip": "{attribute} 必須是一個有效的 IP 位址",
            "ipv4": "{attribute} 必須是一個有效的 IPv4 位址",
            "ipv6": "{attribute} 必須是一個有效的 IPv6 位址",
            "json": "{attribute} 必須是正確的 JSON 字串",
            "max": {
                "numeric": "{attribute} 不能大於 {max}",
                "file": "{attribute} 不能大於 {max} KB",
                "string": "{attribute} 不能多於 {max} 個字元",
                "array": "{attribute} 最多有 {max} 個元素"
            },
            "mimes": "{attribute} 必須為 {values} 的檔案",
            "mimetypes": "{attribute} 必須為 {values} 的檔案",
            "min": {
                "numeric": "{attribute} 不能小於 {min}",
                "file": "{attribute} 不能小於 {min} KB",
                "string": "{attribute} 不能小於 {min} 個字元",
                "array": "{attribute} 至少有 {min} 個元素"
            },
            "not_in": "所選擇的 {attribute} 選項無效",
            "numeric": "{attribute} 欄位必須是數字",
            "present": "{attribute} 必須存在",
            "regex": "{attribute} 格式無效",
            "required": "{attribute} 欄位必需輸入或選擇",
            "required_if": "當 {other} 是 {value} 時 {attribute} 不能留空",
            "required_unless": "當 {other} 不是 {values} 時 {attribute} 不能留空",
            "required_with": "當 {values} 出現時 {attribute} 不能留空",
            "required_with_all": "當 {values} 出現時 {attribute} 不能為空",
            "required_without": "當 {values} 留空時 {attribute} field 不能留空",
            "required_without_all": "當 {values} 都不出現時 {attribute} 不能留空",
            "same": "{attribute} 與 {other} 必須相同",
            "size": {
                "numeric": "{attribute} 的大小必須是 {size}",
                "file": "{attribute} 的大小必須是 {size} KB",
                "string": "{attribute} 必須是 {size} 個字元",
                "array": "{attribute} 必須是 {size} 個元素"
            },
            "string": "{attribute} 欄位只接受文字",
            "timezone": "{attribute} 必須是一個正確的時區值",
            "unique": "{attribute} 已經存在",
            "uploaded": "{attribute} 上傳失敗",
            "url": "{attribute} 的格式錯誤",
            "uuid": "{attribute} 必須是有效的 UUID",
            "custom": {
                "member_verified": {
                    "required": "帳戶尚未驗證"
                },
                "member_status": {
                    "required": "帳戶尚未驗證"
                },
                "picked_point_redeem": {
                    "numeric": "選擇積分優惠不正確",
                    "max": "選擇積分優惠不正確"
                },
                "confirm_order_and_address": {
                    "required": "必須選取確認訂單及核對地址正確"
                }
            },
            "attributes": {
                "email": "電郵地址",
                "password": "密碼",
                "password_confirm": "確認密碼",
                "username": "用戶名稱",
                "familyname": "姓",
                "firstname": "名",
                "gender": "性別",
                "tel": "手提電話",
                "age_range": "年齡組別",
                "address_1": "地址(列1)",
                "address_2": "地址(列2)",
                "address_3": "地址(列3)",
                "address_4": "地址(列4)",
                "age_group": "年齡組別",
                "income_group": "入息範圍",
                "picked_shipment_country": "國家/地區",
                "picked_delivery_method": "送貨方式",
                "picked_payment_method": "付款方式",
                "picked_district_method": "地區",
                "picked_area_method": "區域",
                "delivery_postal_code": "郵政編號",
                "picked_point_redeem": "選擇積分優惠",
                "delivery_address": "送貨地址",
                "delivery_city": "城市",
                "fullname": "姓名",
                "remarks": "訂單備註",
                "declared_value_for_custom": "報關價值",
                "captcha_success": "人機驗證",
                "delivery_store_code": "點碼",
                "name": "名稱",
                "qty": "數量",
                "unit_price": "價格",
                "type": "類別",
                "offline_payment_receipt": "入數紀錄"
            }
        }
    }
}
